import React from "react"
import "./MgVipForm.scss"
import Form from "../forms/mg-vip-form"

const MgVipForm = () => {
  console.log("yes")
  return (
    <div className="mg_vip_form_wrapper">
      <Form />
    </div>
  )
}

export default MgVipForm
