import { Link } from "@StarberryUtils";
import React from "react";
import ReactMarkdown from "react-markdown"

import { GetURL } from "../../common/site/functions";

const MgWorkTile =(props)=> {
	let url = GetURL(props.url.id)
	return (
		<Link to={`/${url}`}>
	        <div className="info">
	          <div className="info-head d-flex justify-content-between align-items-center">  
	              <h4>{props.label}</h4>
	              <Link to={`/${url}`}><i className="icon-lg-arrow-white"></i></Link> 
	          </div>
	          {props.content ? <ReactMarkdown source={props.content} allowDangerousHtml /> : '' }
	        </div>
        </Link>
	)
}

export default MgWorkTile
