import React, { useRef, useState, useEffect } from "react"
import { Link } from "@StarberryUtils";
import { Form, Button } from "react-bootstrap"
import { getUser } from "@myaccountServices/store/utils"

import InputField from './elements/input'
import SelectField from './elements/select'
import RadioField from './elements/radio'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import $ from 'jquery'


import "../../scss/forms.scss";

import axios from "axios"
import * as qs from "query-string"

function RecommForm(props) {
  return (
    <div className="career-form form stbform valuation-main recommendation-form make-payment-form">
    <div/>

     <div className="alert-success">
      {/* <p>{fields[0].success_text}</p> */}
      <div className="payment-success">
        <i className="icon-circle-tick"></i>
        <h3>Your payment has been processed successfully</h3>
        <Link to="/" class="btn">Back to homepage</Link>
        <Link to="../" class="btn btn-success">Make Another payment</Link>
      </div>
    </div>

    </div>
  );
}


const RecommFormPage = (props) => (
  <RecommForm />
)

export default RecommFormPage
