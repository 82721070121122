import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap"
import { createPortal } from 'react-dom';
import { Link } from "@StarberryUtils";
import ReactPlayer from 'react-player'
import { useLocation } from "@reach/router"
import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile
} from "react-device-detect";
import $ from "jquery"

const ModalWindow = ({ children }) => {
    // element to which the modal will be rendered
    const el = document.createElement("div");
    // add a class to the container div for styling
    // el.className = "modal-portal-container";
    useEffect(() => {
        const modalRoot = document.body;
        // append to root when the children of Modal are mounted
        modalRoot.appendChild(el);
        // do a cleanup
        return () => {
            modalRoot.removeChild(el);
        };
    }, [el]);

    return(
        createPortal(
            children,
            el
        )
    )
}


const NewsCustomVideo = (props) => {
    const [isOpen, setOpen] = useState(false);

    let video_url = ''
    if(props.url_1 !== null) {
        video_url = props.url_1
    }
    else if(props.url_2 !== null) {
        video_url = props.url_2
    }


     const trackerVideo = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Video Play Btn',
          'formType': event,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
        });
    }
    
  const [isPlay, setPlay] = useState(false);
  const vidRef = useRef(null);
  const [show, setShow] = useState(false);
  const [play, setPlayvideo] = useState(true);
  const [mute, setMute] = useState(true);
  const [controls, setVideoControls] = useState(false);
  
  
  const [showHTMLVideo, setHTMLVideo] = React.useState(false);
  const pausevideo = (ref) => {
    setPlayvideo(false)
    setVideoControls(false)
  
  }
  const playvideo = (ref) => {
    setPlayvideo(true)
    setVideoControls(false)
    setTimeout(function(){ $(".modal-bg-black").hide() }, 1700);
  }
  const showControls = () => {
    setVideoControls(true)
  }
  
  
    return(
        <>
        <figure>
            <img loading="lazy" src={`${props.image}`} alt="image" />
            {video_url ? <strong className="play-btn" tabIndex="0" onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); }}><i className="icon-play-icon"></i></strong> : '' }
        </figure>

        {showHTMLVideo  &&
                <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-video"
                aria-labelledby="example-custom-modal-styling-title"
                backdropClassName="video-backdrop"
                className="area-guide-full-video-popup"
                >
                <Modal.Header closeButton>
                </Modal.Header>
                    <Modal.Body>

                    {isMobile &&
                    <div className="modal-bg-black">
                    </div>
                    }
                    <ReactPlayer frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen autoplay ref={vidRef} onPlay={trackerVideo(props.title)} url={video_url} modestbranding="1"  controls={isMobile && isIOS ?true:false} autoplay={true} muted={isMobile && isIOS ? mute : false} playsinline={true} playing={play} onEnded={() => { setHTMLVideo(false) }} width='100%' height='100%' />
                 
                    </Modal.Body>
                </Modal>

            }
        </>
    )
}

export default NewsCustomVideo

