import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react"
import { Modal } from "react-bootstrap"
import { useMatch } from "@reach/router"
import "./largeVideo.scss"
import listImg from "../../../images/list.svg"
import videoPerson from "../../../images/person-video-img.jpg"
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss';
import { createPortal } from 'react-dom';
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

import { MEET_THE_TEAM_PAGE_URL } from "../../common/site/constants";

import ReactPlayer from 'react-player'
import { useLocation } from "@reach/router"
import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile
} from "react-device-detect";


const ModalWindow = ({ children }) => {
    // element to which the modal will be rendered
    const el = document.createElement("div");
    // add a class to the container div for styling
    // el.className = "modal-portal-container";
    useEffect(() => {
        const modalRoot = document.body;
        // append to root when the children of Modal are mounted
        modalRoot.appendChild(el);
        // do a cleanup
        return () => {
            modalRoot.removeChild(el);
        };
    }, [el]);

    return(
        createPortal(
            children,
            el
        )
    )
}

const LargeVideo = (props) => {

  const [isOpen, setOpen] = useState(false);

     const trackerVideo = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Video Play Btn',
          'formType': event,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
        });
    }

    const [isPlay, setPlay] = useState(false);
    const vidRef = useRef(null);
    const [show, setShow] = useState(false);
    const [play, setPlayvideo] = useState(true);
    const [mute, setMute] = useState(true);
    const [controls, setVideoControls] = useState(false);

    const PeopleDetails = useMatch(MEET_THE_TEAM_PAGE_URL.alias+"/:item/")
    
    const [showHTMLVideo, setHTMLVideo] = React.useState(false);
    const pausevideo = (ref) => {
      setPlayvideo(false)
      setVideoControls(false)
    
    }
    const playvideo = (ref) => {
      setPlayvideo(true)
      setVideoControls(false)
    }
    const showControls = () => {
      setVideoControls(true)
    }

    //const video_url = props.videoask
    
    //const pageurl = typeof window !== 'undefined' ? window.location.href : ''

  return (
    <>
    <div className="person-details-img">
            <ImageTransform imagesources={props.Full_Image} renderer="srcSet" imagename="people.Full_Image.details" attr={{ alt: props.name + ' - Martyn Gerrard', class:'d-xl-block img-video-large' }} imagetransformresult={props.processedImages} id={props.id}/>

           {props.video_url ? <strong className="play-btn" tabIndex="0" onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); }}><i className="icon-play-transparent"></i></strong> : '' }
           </div>


           {showHTMLVideo  &&
                <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-video"
                aria-labelledby="example-custom-modal-styling-title"
                backdropClassName="video-backdrop"
                className="area-guide-full-video-popup"
                >
                <Modal.Header closeButton>
                </Modal.Header>
                    <Modal.Body>

                    <ReactPlayer frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen autoplay ref={vidRef} onPlay={trackerVideo(props.title)} url={props.video_url} modestbranding="1"  controls={isMobile && isIOS ?true:false} autoplay={true} muted={isMobile && isIOS ? mute : false} playsinline={true} playing={play} onEnded={() => { setHTMLVideo(false) }} width='100%' height='100%' />
                 
                    </Modal.Body>
                </Modal>

            }

           </>
   )
}

export default LargeVideo
