import React, { useState, useEffect } from "react"
import "./valuation.scss"
import ReactMarkdown from "react-markdown"
import {Col, Row, Container } from 'react-bootstrap';
import { Link } from "@StarberryUtils";
import $ from "jquery";
import Form from "../forms/valuation-form";
import OnlineForm from "../forms/online-valuation";
import Breadcrumbs from "../MgServices/Breadcrumb/Breadcrumb"
import { useQueryParam, StringParam } from 'use-query-params';

const ValuationLanding=(props) => {

    // const [office, setOffice] = useQueryParam('office', StringParam);
    // const [ officeEmail, setOfficeEmail] = useQueryParam('office_email', StringParam);
    const [form, setForm] = useState('');

    function onClick(e) {
      $('.valuation-main-landing').hide();
      $('.valuation-sub-pages').show();
    }

    let office;
    let officeEmail;

    if (props.locationState) {
      office = props.locationState.office;
      officeEmail = props.locationState.office_email;
    }

    const img = ''
  return (
      <div className="event-section">
      <div className="valuation-sub-pages">        
      {props.Modules.map((Modules, i) => {
        return (
          <>
            {Modules.Inner_Steps_Background_Image ?
            <><div className="valuation-banner">
              <picture>
                <source media="(min-width:1699px)" srcset={Modules.Inner_Steps_Background_Image.publicURL} />
                <source media="(min-width:992px)" srcset={Modules.Inner_Steps_Background_Image.publicURL} />
                <source media="(min-width:768px)" srcset={Modules.Inner_Steps_Background_Image.publicURL} />
                <img loading="lazy" className="bg-img" src={Modules.Inner_Steps_Background_Image.publicURL} alt="BannerImg" />
              </picture>
            </div>
            <div className="event-wrap main-event-wrap">
              {props.Page.all_menus ? <Breadcrumbs id={props.Page.all_menus[0].id} tag="market-property" /> : '' }
              <div className="event-title">
                <h1 id="form-head">Firstly, we need some<br/>property information.</h1>
              </div>
              {form && (
                  <>
                      {form == "instant valuation" ?
                           <OnlineForm office={office} officeEmail={officeEmail}/> :
                           <Form office={office} officeEmail={officeEmail}/> 
                      }
                  </>
              )}
              <div className="valuation-success-btns">
                <Link to="/property/for-sale/in-london/" className="btn">Find a Home</Link>
                <Link to="/contact" className="btn">Contact</Link>
              </div>
            </div>
            </> : ''
            }
          </>
        )
      })}
      </div>

      {props.Modules.map((Modules, i) => {
          return (
          <div className="valuation-main-landing"> 
          {Modules.Landing_Background_Image ? 
            <div className="valuation-banner">
              <picture>
                <source media="(min-width:1699px)" srcset={Modules.Landing_Background_Image.publicURL} />
                <source media="(min-width:992px)" srcset={Modules.Landing_Background_Image.publicURL} />
                <source media="(min-width:768px)" srcset={Modules.Landing_Background_Image.publicURL} />
                <img loading="lazy" className="bg-img-main" src={Modules.Landing_Background_Image.publicURL} alt="BannerImg" />
              </picture>
            </div> : '' }

            <div className="event-wrap landing-wrap">
              {props.Page.all_menus ? <Breadcrumbs id={props.Page.all_menus[0].id} tag="market-property" /> : '' }
              <div className="event-title">
                {Modules.Landing_Page_Content ? 
                  <ReactMarkdown source={Modules.Landing_Page_Content} allowDangerousHtml /> : '' }
              </div>

              <Container>
                <Row>
                  {Modules.Add_Valuation_Block.map((block, i) => {
                    return (
                      <Col lg={6}>
                        <div className={`valuation-div ${i}`}>
                          <h2>{block.Title}</h2>
                          <ReactMarkdown source={block.Content} allowDangerousHtml />
                          {block.CTA_Link ? (
                            <a
                              href={block.CTA_Link}
                              target={"_blank"}
                              className="btn"
                            >
                              {block.CTA_Label}
                            </a>
                          ) : (
                            <Link
                              to={"#"}
                              className="btn"
                              onClick={() => {
                                setForm(block.CTA_Label)
                                onClick()
                              }}
                            >
                              {block.CTA_Label}
                            </Link>
                          )}
                        </div>
                      </Col>
                    )
                  })}
                </Row>
              </Container>

            </div>
          </div>
          )
        })}

      </div>

  );
}

export default ValuationLanding;
