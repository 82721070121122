import { Link } from "@StarberryUtils";
import React,{useState, useEffect} from "react";
import Slider from 'react-slick';
import {Col, Row, Container } from 'react-bootstrap';
import  "../CustomerReviews/CustomerReviews.scss";
import customer1 from "../../../images/customer1.jpg";
import customer2 from "../../../images/customer2.jpg";
import customer3 from "../../../images/customer3.jpg";
import customer4 from "../../../images/customer4.jpg";
import VideoPlay from "../../Play/custom-video";

  const CustomerReviews =(props)=> {

    const [isPlay,setPlay] = useState(false);
    const [isPlayn,setPlayn] = useState(false);
    const [isPlaying,setPlaying] = useState(false);
    const [isVplay,setVplay] = useState(false);
 
  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    mobileFirst:true,
    
    responsive: [
      {
          breakpoint: 9999,
          settings: "unslick"
      },
      {
          breakpoint: 767,
           settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
              }
      }
  ]
  }
  
  return(
  <section className="customers-reviews info-reviews">
    <h2>Customer Reviews</h2>

          <Slider className="reviews-slider"  {...settings} >
              {props.Customer_Reviews.map((review, i) => {
                let video_url = ''
                if(review.Embed_Video_URL != null) {
                  video_url = review.Embed_Video_URL
                }
                else if(review.Upload_Video != null) {
                  video_url = review.Upload_Video.url
                }
                return (
                  <div className="review-video">
                      <img loading="lazy" src={`${review.Image.url}`} alt="video" />
                      <VideoPlay url={video_url}/>
                  </div>
                )
              })}             
            
          </Slider>
    
  </section>
)
  }

export default CustomerReviews
