import React,{useState, useEffect} from "react";
import {Row, Col, Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import VideoCard from "./people-tile"
import { useStaticQuery, graphql } from "gatsby";
import $ from "jquery";
import SearchForm from "./search-form"
import _ from "lodash"
import { useLocation } from "@reach/router"
import { parse } from "query-string"

import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import { sort } from "../../utils/sort"

const postsPerPage = 24;
let arrayForHoldingPosts = [];

const filters = {
    hidden: { opacity: 0, y: 30, },
    visible: {
      opacity: 1,
	  y: 0,
      transition: {
        ease: [0.33, 1, 0.68, 1],
        duration: 0.4
      }
    }
}
const containerItem = {
    hidden: { opacity: 0, y: 50, scale: 0.8 },
    visible: {
      opacity: 1,
	  y: 0,
	  scale: 1,
      transition: {
        ease: [0.33, 1, 0.68, 1],
        duration: 0.8
      }
    }
}
const MotionNavbar = motion(Navbar)
const MotionCol = motion(Col)

const PeopleLanding = () => {

	
	const [postsToShow, setPostsToShow] = useState([]);
	const [next, setNext] = useState(24);
	

    const data = useStaticQuery(graphql`
    query{
	  allStrapiPeople(sort: {fields: Name, order: ASC}) {
	    edges {
	      node {
	        Name
	        Designation
	        URL
	        Phone
	        Embed_Video_URL
			Membership
			Sort
	        Tile_Image {
	          childImageSharp {
	            gatsbyImageData(formats: AUTO
	              width: 300
	              quality: 80
	              layout: FIXED
	              transformOptions: {cropFocus: CENTER, fit: COVER}
	            )
	          }
	        }
	        Category {
	          Name
	          URL
	        }
	        Offices {
	          URL
	        }
	        imagetransforms {
	          Full_Image_Transforms
	          Tile_Image_Transforms
	        }
	        id
	      }
	    }
	  }

	  allStrapiPeopleCategories(filter: {Publish: {eq: true}}) {
	    edges {
	      node {
	        Name
	        URL
	      }
	    }
	  }

	  allStrapiOffices(filter: {Publish: {eq: true}}) {
	    edges {
	      node {
	        Name
	        URL
	      }
	    }
	  }


    }
  `);

  const allPeople = data.allStrapiPeople.edges

  const Sortpeoplelist = allPeople.sort((a, b) => sort(a.Sort, b.Sort))
  const allpeoplelist = Sortpeoplelist

  const location = useLocation();
  const searchParams = parse(location.search) // => {init: "true"}
  var categ_url = searchParams.category
  const [category_url, setCategoryURL] = React.useState(categ_url)

  const [allpeople, setMGPeople] = React.useState([])
	
useEffect(()=>{
	var num_start = 500

	let sortval = _.each(allpeoplelist, item => {
		if(_.toNumber(item.node.Sort) == 0) {
		  item.Sort = num_start = num_start + 1
		}
		else {
			item.Sort = _.toNumber(item.node.Sort)
		}
	  });
  
	// Use of _.sortBy() method
	setMGPeople(_.sortBy(sortval, [function(o) { return o.Sort; }]))

	document.addEventListener('click',outSideLink)
  },[])


  var cat_array = []

  const [category, setCategory] = React.useState("all")
  const [loadtext, setLoadText] = React.useState("")
  const [categoryname, setCategoryName] = React.useState("Everyone")
  const [officecat, setOfficeCat] = React.useState("all_offices")


  const [filterupdate, setFilterUpdate] = React.useState(false)

  const [catupdate, setCatUpdate] = React.useState(1)




  const [ search_text, setSearchText ] = useState('');
  const [ office_text, setOfficeText ] = useState('By Office');

  const [ filteredTeam, setFilteredTeam ] = useState([]);

  const handlechange = (event) => {
    // remove initial empty spaces
	setCatUpdate(1)
    var search_val = event.target.value.trimStart();
    setSearchText(search_val);
    setOfficeText("All Offices");
	setCategoryName("Everyone");
	setCategory("all");
	setOfficeCat("all_offices")
	setFilterUpdate(true)
  }


  const[addSearch,setAddSearch] = useState(false);

    const addingSearch=()=>{
      setAddSearch(!addSearch)
      $(".nav-link").removeClass("active-head");
  }


    const  outSideLink= (e) => {
    if(!e.target.className.includes("icon-search-icon") && !e.target.className.includes('form-control') && !e.target.className.includes('search-field d-flex align-items-center') ) {
      setAddSearch(addSearch)
    }
      }


    function activeclass(e) {
    	$(".nav-link").removeClass("active-head");
    	$("."+e).addClass("active-head");
    	setOfficeText("All Offices");
    	$(".dropdown-item").removeClass("active");

    }

    function activeoffice(e) {
    	$(".nav-link").removeClass("active-head");

    }


	
const TeamFunctions = (url) => {
	setCatUpdate(2);
	setCategory(url);
	if(url == 'all') {
		filteredList();
	}
	setSearchText("");
	setOfficeCat("all_offices")
	activeclass(url);
	setNext(24)
	setCategoryURL('')
	setFilterUpdate(true)
}

	// var filteredTeam = []

const filteredList = () => {
	setFilteredTeam([])
	
	setLoadText("Sorry, No people found in this category")


	var filteredTeamList = []
	if(catupdate == 2) {
		setCatUpdate(3)
	}
	if(category == "all" && search_text == '' && officecat == 'all_offices') {
		filteredTeamList = allpeople
		if(category_url) {
			filteredTeamList = allpeople.filter(peoples => {
				let main_category = peoples.node.Category.filter(cat => _.includes(cat, category_url));
				return main_category.length > 0
			});
		}
		// setFilteredTeam(allpeople)
	}
	else {
		// setFilteredTeam(allpeople)
		if(search_text) {


			for(var i=0; i < allpeople.length; i++) {
				var full_name = allpeople[i].node.Name.toLowerCase()
				if(full_name.includes(search_text))
				{
					filteredTeamList.push(allpeople[i])
				}
			}		
			// setFilteredTeam(filteredTeamList)	
		}
		else
		{
			if(category && officecat == "all_offices") {
				filteredTeamList = allpeople.filter(peoples => {
					let main_category = peoples.node.Category.filter(cat => _.includes(cat, category));
					return main_category.length > 0
				});
			}

			else if(officecat && category == "all") {
				filteredTeamList = allpeople.filter(peopless => {
					let main_category_one = peopless.node.Offices.filter(cat => _.includes(cat, officecat));
					return main_category_one.length > 0
				});

			}

		}
	}
	setFilteredTeam(filteredTeamList)
	setFilterUpdate(false)
}


	
	const loopWithSlice = (start, end) => {
		const slicedPosts = filteredTeam.slice(start, end);
		arrayForHoldingPosts = [...slicedPosts];
		setPostsToShow(arrayForHoldingPosts);
	  };
	
	  const loopWithSliceMore = (start, end) => {
		//   alert(start)
		//   alert(end)
		//   alert(filteredTeam.length)
		const slicedPosts = filteredTeam.slice(start, end);

		arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
		setPostsToShow(arrayForHoldingPosts);
		// alert("wwww",arrayForHoldingPosts)
	  };
	
	  const handleShowMorePosts = () => {
		// filteredList()
		loopWithSliceMore(next, next + postsPerPage);
		setNext(next + postsPerPage);
	  };
	
	
	  useEffect(() => {
		if((catupdate == 1 || catupdate == 2) && (filteredTeam.length == 0 || filterupdate)) {
			filteredList()
			setFilterUpdate(false)
		}
		loopWithSlice(0, postsPerPage);
	  }, [filteredTeam, category, search_text, officecat]);
	
	
	  const all_post_length = filteredTeam.length

	return(
    <div className="review-testimoials people-listing">
      <Container>

        <div className="news-head text-center" >
			<Container>
				<Row className="align-items-center"> 
					<Col lg={12}>
						<InView {...inViewOptions}>
							{({ ref, inView }) => (
								<MotionNavbar
									className="justify-content-center category-list-team"
									ref={ref}
									initial="hidden"
									animate={inView ? "visible" : "hidden"}
									variants={filters}
								>
									<Nav className="text-center">
										<span className={`nav-link all ${category_url == "all" && "active-head"} people-filter-desktop`} onClick={() => {TeamFunctions("all");setCategoryName("Everyone");}} data-filter="all" >Everyone</span>

										{data.allStrapiPeopleCategories.edges.map(({node}, index, active) => {
										return (
										<span className={`nav-link ${node.URL} ${category_url == node.URL ? "active-head" : ''}  people-filter-desktop`} key={index} onClick={() => {TeamFunctions(node.URL);}}  data-filter={node.URL}>{node.Name}</span>
										)
										})}

										<NavDropdown title={categoryname} id="nav-dropdown office-name-dropdown-title" className="people-office-menu-head people-listing-office-list people-filter-mobile"> 

										<NavDropdown.Item eventKey={`0`} onClick={() => {TeamFunctions("all");setCategoryName("Everyone");}}  data-filter={`all`} >Everyone</NavDropdown.Item>

										{data.allStrapiPeopleCategories.edges.map(({node}, index, active) => {
										return (
										<NavDropdown.Item eventKey={index+1} onClick={() => {TeamFunctions(node.URL);setCategoryName(node.Name);}}  data-filter={node.URL} >{node.Name}</NavDropdown.Item>

										)
										})}
										</NavDropdown>
										
										<NavDropdown title={office_text} id="nav-dropdown office-name-dropdown-title" className="people-office-menu-head people-listing-office-list"> 
										
										<NavDropdown.Item eventKey={`0`} onClick={() => {TeamFunctions("all");}}  data-filter={`all`} >All Offices</NavDropdown.Item>

										{data.allStrapiOffices.edges.map(({node}, index, active) => {
										return (
										<NavDropdown.Item eventKey={index+1} onClick={() => {setCatUpdate(2);setCategory("all");setOfficeCat(node.URL);setSearchText("");setFilterUpdate(true);setCategoryName("Everyone");setOfficeText(node.Name.length > 12 ? node.Name.substring(0,12)+'..' : node.Name);activeoffice();setNext(24)}}  data-filter={node.URL} >{node.Name}</NavDropdown.Item>)
										})}
										</NavDropdown>

										<Nav.Link className="search-control" href="#"><i className="icon-search-icon" onClick={e=>addingSearch()}></i></Nav.Link>
										{
										addSearch ?
										<SearchForm handlechange={handlechange}/>
										:
										!addSearch
										}
									</Nav>
								</MotionNavbar>
							)}
						</InView>
					</Col>
      			</Row>
    		</Container>
  		</div>


		{filteredTeam.length === 0 ?
			<div className="no-people">
				<p>{loadtext}
				</p>
			</div>
		:
			<Row>
				{postsToShow.map(({node}, index) => {
					cat_array = []
					{node.Category.map((catname) => {
						cat_array.push(catname.URL)
					})}	
					{node.Offices.map((offname) => {
						cat_array.push(offname.URL)
					})}

					let video_url = ''
					if(node.Embed_Video_URL != null)
					{
						video_url = node.Embed_Video_URL
					}
					else if(node.Upload_Video != null) {
						video_url = node.Upload_Video.url
					}


					const image_url = node.Tile_Image


					let processedImages = JSON.stringify({});
					if (node?.imagetransforms?.Tile_Image_Transforms) {
						processedImages = node.imagetransforms.Tile_Image_Transforms;
					}

					return (
						<InView {...inViewOptions}>
							{({ ref, inView }) => (
								<MotionCol
									md={3}
									xl={2}
									xs={6}
									className={`team-wrap ${cat_array.join(' ')}`}
									ref={ref}
									initial="hidden"
									animate={inView ? "visible" : "hidden"}
									variants={containerItem}
								>
									<VideoCard
										videoTitle={node.Name}
										membership={node.Membership}
										schedule={node.Designation}
										contactNo={node.Phone}
										url={node.URL}
										image={image_url}
										video_url={video_url}
										id={node.id}
										processedImages={processedImages}
									/>
								</MotionCol>
							)}
						</InView>
					)
				})}

				{arrayForHoldingPosts.length !== all_post_length ?
				<div className="area-guide-load-more people-landing-load-more"><button className="btn btn-pagination" onClick={() => handleShowMorePosts()}>Load more</button></div>
				: ''
				}
				
			</Row>
		}

      	</Container>
    </div>
	)
}


export default PeopleLanding