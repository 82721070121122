import React from "react";
import { Slider, Handles, Rail, Tracks, Ticks } from 'react-compound-slider';
import "./MarkerSlide.scss";
import "./MarkerCustom.scss";
import $ from "jquery";

import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const filters = {
    hidden: { opacity: 0, y: 30, },
    visible: {
      opacity: 1,
	  y: 0,
      transition: {
        ease: [0.33, 1, 0.68, 1],
        duration: 0.4
      }
    }
}

const MarkerSlide = (props) => {

  function change(v) {
  $('.year-txt').removeClass('active');
  $('.'+v).addClass("active");

  $('html, body').animate({
        scrollTop: $("#"+v).offset().top-130
    }, 2000);
  }

    const sliderStyle = {
        position: 'relative',
        width: '40%',
        height: 80,
      }
      
      const railStyle = {
        position: 'absolute',
        width: '100%',
        height: 2,
        marginTop: 35,
        borderRadius: 5,
        backgroundColor: '#DDDDDD',
      }

     function Handle({
        handle: { id, value, percent },
        getHandleProps
      }) {
        return (
          <div
            style={{
              left: `${percent}%`,
              position: 'absolute',
              marginLeft: -10,
              marginTop: 30,
              zIndex: 2,
              width: 12,
              height: 12,
              border: 0,
              textAlign: 'center',
              cursor: 'pointer',
              borderRadius: '50%',
              backgroundColor: '#005480',
              color: '#333',
            }}
            {...getHandleProps(id)}
          >
            <div className="value-txt" style={{ fontFamily: 'Roboto', fontSize: 11, marginTop: 30 }}>
              {value}
            </div>
          </div>
        )
      }

      function Track({ source, target, getTrackProps }) {
        return (
          <div
            style={{
              position: 'absolute',
              height: 2,
              zIndex: 1,
              marginTop: 35,
              backgroundColor: '#005480',
              borderRadius: 5,
              cursor: 'pointer',
              left: `${source.percent}%`,
              width: `${target.percent - source.percent}%`,
            }}
            {...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */}
          />
        )
      }

      function Tick({ tick, count }) {
        return (
          <div >
            <div
              style={{
                position: 'absolute',
                marginTop: 15,
                marginLeft: -0.5,
                width: 1,
                height: 8,
                backgroundColor: '#fafafa',
                left: `${tick.percent}%`,
              }}
            />
            <div id={props.year} className={`year-txt ${tick.value}`}
              style={{
                position: 'absolute',
                marginTop: -5,
                fontSize: 10,
                textAlign: 'center',
                marginLeft: `${-(100 / count) / 2}%`,
                width: `${100 / count}%`,
                left: `${tick.percent}%`,
              }}
              onClick={v => change(tick.value)}
            >
              {tick.value}
            </div>
          </div>
        )
      }
      

    return (
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.div
            className="year-slider container"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={filters}
          >
            <Slider
            onChange={v => change(v[0])}
            rootStyle={sliderStyle}
            domain={[2008,2020]}
            step={1}
            reversed={true}
            mode={1}
            values={[2020]}
              style={{
                cursor: 'pointer',
              }}
            >
        <Rail>
          {({ getRailProps }) => (
            <div style={railStyle} {...getRailProps()} />
          )}
        </Rail>
          <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  getHandleProps={getHandleProps}
                  className="handle"
                  style={{
                    cursor: 'pointer',
                  }}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
        <Ticks count={15 /* generate approximately 15 ticks within the domain */}>
          {({ ticks }) => (
            <div className="slider-ticks">
              {ticks.map(tick => (
                <Tick key={tick.id} tick={tick} count={ticks.length} />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    </motion.div>
  
    )}
  </InView>
  )
}

export default MarkerSlide;