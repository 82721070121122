import React,{Fragment, useEffect} from 'react'
import { Link } from "@StarberryUtils";
import OfficePage from "./OfficePage/OfficePage"

import "./OurOffices.scss"

const OurOfficesLandingPage  = (props) => {
	return (
		<React.Fragment>
			<div className="content ouroffice-page">
				<OfficePage id={props.id} Modules={props.Modules} />
			</div>
		</React.Fragment>)
}


export default OurOfficesLandingPage;