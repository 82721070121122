import React, { useState } from 'react';
import { Row, Container } from 'react-bootstrap';
import LocRatingMap from '../map/loc-rating-map-office';
import { MapMarkerInfoBox } from '../map/map-marker';


/**
 * Components
 */

const OfficeLandingMapFilter = (props) => {
    const [] = useState('')

    const data = props.offices.edges
    var count = ''
    var office_list = [];
    for(var i=0; i < data.length; i++){
        let title = data[i].node.Name.toLowerCase()
        let titlec = data[i].node.Name.toUpperCase()
        let areas = data[i].node.Areas ? data[i].node.Areas.toLowerCase() : ''
        let areasc = data[i].node.Areas ? data[i].node.Areas.toUpperCase() : ''
        let address = data[i].node.Address ? data[i].node.Address.toLowerCase() : ''
        let addressc = data[i].node.Address ? data[i].node.Address.toUpperCase() : ''
        let postcodes = data[i].node.Postcode ? data[i].node.Postcode.toLowerCase() : ''
        let postcodesc = data[i].node.Postcode ? data[i].node.Postcode.toUpperCase() : ''
        if(title.includes(props.text) || titlec.includes(props.text)) {
            office_list.push(data[i])
        }
        else if(address.includes(props.text.toLowerCase()) || addressc.includes(props.text)) {
            office_list.push(data[i])
        }
        else if(areas.includes(props.text.toLowerCase()) || areasc.includes(props.text)) {
            office_list.push(data[i])
        }
        else if(postcodes.includes(props.text.toLowerCase()) || postcodesc.includes(props.text)) {
            office_list.push(data[i])
        }
    }

    if(office_list.length == 0) {
        count = 'empty'
        office_list = data
    }
    else {
        count = ''
    }

    // Get Map Details
    let mapItems = [];
    {office_list.map(({node}, key) => {
    let mapItem = {};
    mapItem['lat']  = node.Latitude      
    mapItem['lng']  = node.Longtitude
    mapItem['id']  = node.id
    const mapMarkerImageShowFlag = 0;
    //let mapItemHtml = MapMarkerInfoBox(node.Name, mapMarkerImageShowFlag);
    // console.log( mapItemHtml );
    //mapItem['html'] = mapItemHtml;
    mapItems.push( mapItem );
    })};
    // console.log("mapppppp",mapItems);
    return (
        <div className="property-map" id="property-map">
        {mapItems.length != 0 &&
            <LocRatingMap data={mapItems} />
        }
        </div>
    )
}

export default OfficeLandingMapFilter
