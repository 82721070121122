import { Link } from "@StarberryUtils";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Row, Container } from 'react-bootstrap';
// import VideoCard from '../VideoCard/VideoCard';
import "./OfficeCard.scss";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const contentItem = {
    hidden: { opacity: 0, x: -30 },
    visible: (custom) => ({
        opacity: 1,
        x: 0,
        transition: {
            delay: custom * 0.02,
            duration: 0.4,
            ease: [0.33, 1, 0.68, 1],
        }
    })
  }

function OfficeCard(props) {

    return (
        <section className="office-list">
            {
                props.offices.map(({ node }, i) => {

                    const image_url = node.Tile_Image.internal.description ? node.Tile_Image.internal.description.replace("File ", "").replace('"', '').replace('"', '') : '';

                    let processedImages = JSON.stringify({});
                    if (node?.imagetransforms?.Tile_Image_Transforms) {
                        processedImages = node.imagetransforms.Tile_Image_Transforms;
                    }

                    return (
                        <InView threshold={0.2} key={i}>
                            {({ ref, inView }) => (
                                <motion.div
                                    ref={ref}
                                    initial="hidden"
                                    animate={inView ? "visible" : "hidden"}
                                    variants={contentItem}
                                    custom={i}
                                >
                                    <Link to={node.URL}>
                                        <div className="office-box" id={node.id} >
                                            <div className="office-box-img">
                                                <figure>
                                                    {
                                                        i == 0 ?
                                                            <picture>

                                                                <ImageTransform imagesources={image_url} renderer="srcSet" imagename="offices.Tile_Image.tile" attr={{ alt: node.Name + ' - Martyn Gerrard', class: '' }} imagetransformresult={processedImages} id={node.strapiId} />

                                                            </picture>
                                                            : <React.Fragment>

                                                                <ImageTransform imagesources={image_url} renderer="srcSet" imagename="offices.Tile_Image.tile" attr={{ alt: node.Name + ' - Martyn Gerrard', class: 'd-none d-sm-block' }} imagetransformresult={processedImages} id={node.strapiId} />

                                                                <ImageTransform imagesources={image_url} renderer="srcSet" imagename="offices.Tile_Image.tile" attr={{ alt: node.Name + ' - Martyn Gerrard', class: 'd-block d-sm-none' }} imagetransformresult={processedImages} id={node.strapiId} />

                                                            </React.Fragment>
                                                    }
                                                </figure>
                                            </div>
                                            <div className="office-info">
                                                <div className="office-name d-flex align-items-center">
                                                    <strong>{node.Name}</strong>
                                                    <i className="arrow-icon ml-auto"></i>
                                                </div>
                                                <div className="office-address">
                                                    <span className="phone">{node.Phone}</span>
                                                    <span>{node.Address}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </motion.div>
                            )}
                        </InView>
                    )
                })
            }

        </section>
    )
}

export default OfficeCard
