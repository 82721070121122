import React,{useState, useEffect} from 'react'
import { Link } from "@StarberryUtils";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import logoBlack from "../../images/mg-logo-black.svg";

import NewsHead from "./NewsHead/NewsHead"
import NewsPost from "./NewsPost/NewsPost"
import FilterNews from "./filter-news"

const GET_ALL_NEWS = gql`
  query GetNews{	
	  propertyNews(sort:"Date:desc")  {
	    Title
	    URL
	    Image_Type
	    Tile_Image {
	      url
	    }
	    Date
	    Category {
	      Name
	      URL
	    }
	    id
	    imagetransforms
      }
  }
`;


function AllNews() {

  const [ search_text, setSearchText ] = useState('');

  const handlechange = (event) => {
    // remove initial empty spaces
    var search_val = event.target.value.trimStart();
    setSearchText(search_val);
  }


	const { loading, error, data } = useQuery(GET_ALL_NEWS);

  	if (loading) return (
	  
		<section className={"loader-wrapper"}>
		<div id="loader-wrapper">
		  <div id="loader" class="new-loader">
			<div className="new-loader-anime"></div>
			<img loading="lazy" className="logo-white" src={logoBlack} className="loader-logo" alt="logo"/>
		  </div>
		  <div class="loader-section section-left"></div>
		  <div class="loader-section section-right"></div>
		</div>
	  </section>
	  );
  	return (
  		<>

		<div className="news-page">
		    <NewsHead news_data={data.propertyNews} tag="All News" handlechange={handlechange}/>
			{search_text != '' ? <FilterNews text={search_text}/> :
		    <NewsPost data={data.propertyNews} tag="parent"/> }
	    </div>

  		</>
  	)
}



export default AllNews
