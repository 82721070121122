import React from 'react'
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import {Row, Col, Container } from 'react-bootstrap';

import NewsPost from "./NewsPost/NewsPost"


const SEARCH_NEWS_CATEGORY = gql`
  query SearchNewsCat($search_text: String!,$cat: String!){	
	  propertyNews(sort:"Date:desc",where:{Category: {URL:$cat},Title_contains:$search_text})  {
	    Title
	    URL
	    Tile_Image {
	      url
	    }
	    Date
	    Category {
	      Name
	      URL
	    }
	    id
	    imagetransforms
      }
  }
`;


function SearchNews(props) {

    const { loading, error, data } = useQuery(SEARCH_NEWS_CATEGORY, { variables: { search_text:props.text, cat:props.category } });

  	if (loading) return <p>Loading ...</p>;
	return (
	<div className="components-News--filer-news-category">
	{data.propertyNews == "" ? 
  		<div className="news-posts" >
    		<Container>
        		<Row> 
        			<Col lg={12}>
                		<div className="no-match-news">
                			No match found
                		</div>
        			</Col>
        		</Row>
        	</Container>
        </div> : <NewsPost data={data.propertyNews} tag="parent"/>}
	</div>
	)
}

export default SearchNews
