import { Link } from "@StarberryUtils";
import React from "react";
import {Row, Col, Container,Breadcrumb } from 'react-bootstrap';
//import ScrollAnimation from 'react-animate-on-scroll';
import  "./PropertyBreadcrumbs.scss";
//import "animate.css/animate.min.css";

const PropertyBreadcrumbs =(props) => {

    return(
        <div className="breadcrumb-wrp property-breadcrumbs" >

                {/* <ScrollAnimation animateIn='fadeInUp'> */}
                    <Breadcrumb>
                        <Breadcrumb.Item className="text-uppercase"><Link to="/">home</Link></Breadcrumb.Item>
                        <Breadcrumb.Item className="text-uppercase" active> market your property</Breadcrumb.Item>
                    </Breadcrumb>
                    {/* </ScrollAnimation> */}
            
        </div>)
    }

export default PropertyBreadcrumbs;