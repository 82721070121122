import React, { useState } from 'react';
import { Row, Container } from 'react-bootstrap';
import LocRatingMap from '../map/loc-rating-map-office';
import { MapMarkerInfoBox } from '../map/office-map-marker';


/**
 * Components
 */

const OfficeLandingMap = (props) => {
    const [] = useState('')


    // Get Map Details
    let mapItems = [];
    {props.offices.edges.map(({node}, key) => {
    let mapItem = {};
    mapItem['lat']  = node.Latitude      
    mapItem['lng']  = node.Longtitude
    mapItem['id']  = node.id
    const mapMarkerImageShowFlag = 0;

    //let image_url = ''
    //if(node.Tile_Image && node.Tile_Image.internal) {
    //    image_url = node.Tile_Image.internal.description ? node.Tile_Image.internal.description.replace("File ", "").replace('"', '').replace('"', '') : '';
    //}

    //let mapItemHtml = MapMarkerInfoBox(node.Name, mapMarkerImageShowFlag);
    // console.log( mapItemHtml );
    //mapItem['html'] = mapItemHtml;
    mapItems.push( mapItem );
    })};
    // console.log("mapppppp",mapItems);
    return (
        <div className="property-map" id="property-map">
            <LocRatingMap data={mapItems} />
        </div>
    )
}

export default OfficeLandingMap
