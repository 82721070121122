import React from "react"
import { Link } from "@StarberryUtils";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"

import NewsCategories from "../mg/news-categories"

import { GetURL } from "../common/site/functions";


const GET_ALL_NEWS = gql`
  query GetNews{	
	  propertyNews(sort:"createdAt:desc")  {
	    Title
	    URL
	    Tile_Image {
	      url
	    }
	    Date
	    Category {
	      Name
	      URL
	    }
      }
  }
`;


function AllNews() {

	const { loading, error, data } = useQuery(GET_ALL_NEWS);

  	if (loading) return <p>Loading ...</p>;
  	return (
  		<>
  			<NewsCategories />

  			{data.propertyNews.map((node, index) => {					
				return (
					<Link to={node.URL}>
						{node.Title}
						<img loading="lazy" src={`https://martyngerrard-strapi.q.starberry.com${node.Tile_Image.url}`} alt={node.Title} />
						{node.Date}
					</Link>
				)
			})}

  		</>
  	)
}



export default AllNews
