import React,{useEffect, useState} from "react";
import ReactMarkdown from "react-markdown"
import {Col, Row, Container } from 'react-bootstrap';

import Banner from "../components/Home/Banner/banner"
import PlainBanner from "../components/Home/Banner/plain-banner"
import Managing from "../components/Home/managing/managing"
import Started from "../components/Home/started/started"
import Services from "../components/Home/services/services"
import CaseStudies from "../components/Home/CaseStudies/CaseStudies"
import FullVideo from "../components/Home/CaseStudies/full-banner-video"
import Reviews from "../components/Home/Reviews/Reviews"
import AllReviews from "../components/Home/Reviews/AllReviews"
import CanHelp from "../components/Home/CanHelp/CanHelp"
import CanHelpStyleTwo from "../components/Home/CanHelp/CanHelpStyleTwo"
import CanHelpStyleFour from "../components/Home/CanHelp/CanHelpStyleFour"
import CareerForm from "../components/forms/career-form"
import RecommendationForm from "../components/forms/make-a-recommendation-form.js"
import CouponCodeForm from "../components/forms/coupon-code-form"
import MakeaPayment from "../components/forms/make-a-payment.js"
import MakeaPaymentSuccess from "../components/forms/make-a-payment-success.js"
import MakeaPaymentFailure from "../components/forms/make-a-payment-failure.js"
import ReachUsForm from "../components/forms/reach-us-form"
import RegistrationFormPage from "../components/forms/registration.js"
import MgVipForm from "./MgVipForm/MgVipForm"

import LocalLove from "../components/Home/LocalLove"

import Breadcrumbs from "../components/MgServices/Breadcrumb/Breadcrumb"

import ServiceHead from "../components/MgServices/ServiceHead/ServiceHead"
import MgWork from "../components/MgServices/MgWork/MgWork"
import News from "../components/News/news"
import LatestNews from "../components/News/latestnews"
import MarketData from "../components/MarketData/MarketData"
import VideoCategory from "../components/News/video-category"
import VideosLanding from "../components/videos/video-landing"
import CareerVideos from "../components/videos/career-video-landing"

import StaticBanner from "../components/Static/StaticBanner/StaticBanner"
import OfficeNumbers from '../components/Static/OfficeNumbers/OfficeNumbers'
import StaticFeatures from '../components/Static/StaticFeatures/StaticFeatures'
import InstructMG from '../components/Static/instruct-mg/instruct-mg'
import InstructMGStyleTwo from '../components/Static/instruct-mg/instruct-mg-style-two'
import FeaturedProperties from "../components/PropertyDetails/Similar/featured-properties"
import Static from "../components/Static/static-page/default-static"
import StaticContent from "../components/Static/static-page/static-content"
import VideoTiles from "../components/Static/video-tiles"
import ContentBlocks from "../components/Static/content-blocks"
import MarketProperty from "../components/market-your-property/market-your-property"
import PeopleLanding from "../components/Static/our-people/people-listing"
import PartnersBrand from "../components/Static/PartnersBrand/PartnersBrand"
import OfficeLanding from "../components/OurOffices/office-landing"
import AreaGuideLanding from "../components/area-guide/area-guide-landing"
import History from "../components/Static/history/history"
import Jobs from "../components/jobs-landing"
import Fixflow from "../components/fixflow"
import LocalLoveListing from "../components/LocalLoveListing"

import GoogleReviews from "../components/Static/Intro/GoogleReviews"

import GetStarted from "../components/mg/get-started"
import MGReviews from "../components/mg/reviews"
import HowCanWeHelp from "../components/mg/how-can-we-help"
import ServiceTiles from "../components/mg/services-tiles"
import MGAllNews from "../components/mg/all-news"
import MGAllPeople from "../components/mg/all-people"
import Bg from "../images/valuation-banner-1600.jpg"
import BreadcrumbsPayment from "./MgServices/Breadcrumb/BreadcrumbPayment";
import VipContainer from "./VipContainer/VipContainer";
import ReviewList from "./Home/Reviews/ReviewList";
import TrustPilotWidget from "./TrustPilot/TrustPilotWidget";

const Modules = (props) => {

// console.log('modules', props)
    const [renderComponent, setRenderComponent] = useState(false);
    const [headingToggle, setHeadingToggle] = useState(false);
    useEffect(() => {
      window.addEventListener("mousemove", () => {
          if (renderComponent === false) {
              setRenderComponent(true)
          }
      })
      window.addEventListener("touchmove", () => {
          if (renderComponent === false) {
              setRenderComponent(true)
          }
      })
      window.addEventListener("keypress", () => {
        if (renderComponent === false) {
            setRenderComponent(true)
        }
    })

  }, [])

    return <>

        {props.Page.Layout === "Static_Page" && props.Page.Layout !== "Office_Landing_Page" &&
        <>
            <Static Modules={props.Modules} Speak_with={props.Page.Speak_with} id={props.Page.all_menus[0].id}/>
        </>
        }

        {props.Page.Layout === "Office_Landing_Page" &&
            <OfficeLanding Modules={props.Modules}  id={props.Page.all_menus[0].id} />
        }

        {props.Page.Layout === "Fixflow_Page" &&
            <Fixflow Modules={props.Modules}  id={props.Page.all_menus[0].id} />
        }

        {props.Page.Layout !== "Office_Landing_Page" &&
        <>
        {props.Modules.map((Modules, i) => {
            const cutomer_reviews = Modules.customer_reviews && Modules.customer_reviews;
          return (
              <div key={i}>
                  {Modules.Add_Slide !== null &&
                      <>
                      <Banner data={Modules.Add_Slide} page={props.Page.Pagename} />
                      </>
                  }

                  {Modules.Header_Title !== null && Modules.Banner_Image === null &&
                      <>
                      <Breadcrumbs id={props.Page.all_menus[0].id} tag="details" />
                      <ServiceHead Title={Modules.Header_Title} CTA_1_Label={Modules.CTA_1_Label} CTA_2_Label={Modules.CTA_2_Label} CTA_1_URL={Modules.CTA_1_URL} CTA_2_URL={Modules.CTA_2_URL}  CTA_1_Email={Modules.CTA_1_Email} CTA_2_Email={Modules.CTA_2_Email}/>
                      </>
                  }

                  {Modules.Banner_Image !== null &&
                      <>
                      <StaticBanner Title={Modules.Header_Title} CTA_1_Label={Modules.CTA_1_Label} CTA_2_Label={Modules.CTA_2_Label} CTA_1_URL={Modules.CTA_1_URL} CTA_2_URL={Modules.CTA_2_URL} Banner_Image={Modules.Banner_Image} id={props.Page.all_menus[0].id} Show_Review={Modules.Show_Review} page={props.Page.Pagename} layout={props.Page.Layout}  CTA_1_Email={Modules.CTA_1_Email} CTA_2_Email={Modules.CTA_2_Email}/>
                      </>
                  }

                  {Modules.Background_Image !== null && props.Page.Layout === 'Career_Landing_Page' &&
                      <PlainBanner image={Modules.Background_Image} Embed_Video_URL={Modules.Embed_Video_URL} page={props.Page.Pagename}/>
                  }

                  {props.Page.Layout === "Local_Love_Landing_Page" && Modules.Content &&
                      <>
                      <Container className="intro">
                          <Row>
                              <Col lg={9}>
                              <ReactMarkdown source={Modules.Content} allowDangerousHtml />
                              </Col>
                          </Row>
                      </Container>
                      <Container className="main-list">
                          <Row>
                              <Col lg={12}>
                                  <LocalLoveListing />
                              </Col>
                          </Row>
                      </Container>
                      </>
                  }


                  {Modules.Add_New_Block !== null &&
                      <ContentBlocks data={Modules.Add_New_Block}/>
                  }

                  {Modules.Add_Stats !== null &&
                      <OfficeNumbers data={Modules.Add_Stats} class="landing-page"/>
                  }

                  {Modules.Choose_Module === 'MG_Get_Started' &&
                      <Started page={props.Page.Pagename}/>
                  }

                  {Modules.Choose_Style === 'Horizontal_Slider' &&
                      <>
                          <MgWork services={Modules.Add_Services} Service_Links_Heading={Modules.Service_Links_Heading} Choose_Menu_to_Show={Modules.Choose_Menu_to_Show} page={props.Page.Pagename}/>
                      </>
                  }

                  {Modules.Choose_Style !== null &&
                      <>
                      {/*<ServiceTiles style={Modules.Choose_Style} services={Modules.Add_Services} menu={Modules.Choose_Menu_to_Show} service_heading={Modules.Service_Links_Heading}/>*/}
                      </>
                  }

                    {Modules.Heading_Block !== null &&
                      <>
                      <VipContainer Module={Modules} id={props.Page.all_menus[0].id} />
                      </>
                  }

                  {Modules.Choose_Module === 'Reviews' && renderComponent &&
                      <>
                      <Reviews Modules={Modules} />
                      </>
                  } 

                  {Modules.Content !== null && Modules.Static_Page === false &&
                      <StaticContent Content={Modules.Content} breadcrumb="no" Right_Side_Block_Title={Modules.Right_Side_Block_Title} Right_Side_Block_CTA_Label={Modules.Right_Side_Block_CTA_Label} Right_Side_Block_CTA_Link={Modules.Right_Side_Block_CTA_Link} Speak_with={props.Page.Speak_with}/>
                  }

                  {Modules.Reasons !== null && props.Page.Layout !== 'Career_Landing_Page' &&
                      <InstructMG Reasons={Modules.Reasons} Content={Modules.Content} />
                  }

                  {Modules.Add_Video !== null &&
                      <VideoTiles videos={Modules.Add_Video} />
                  }

                  {Modules.Reasons !== null && props.Page.Layout === 'Career_Landing_Page' &&
                      <InstructMGStyleTwo Reasons={Modules.Reasons} Content={Modules.Content}/>
                  }

                  {Modules.Service_Content !== null &&
                      <>
                      <Services Service_Content={Modules.Service_Content} Service_CTA_Label={Modules.Service_CTA_Label} Service_CTA_Link={Modules.Service_CTA_Link} Service_CTA_Label_Two={Modules.Service_CTA_Label_Two} Service_CTA_Link_Two={Modules.Service_CTA_Link_Two} Services={Modules.Add_Services} page={props.Page.Pagename}/>
                      </>
                  }

                  {Modules.Select_News !== null && renderComponent &&
                      <CaseStudies news={Modules.Select_News} title={Modules.Title} content={Modules.Content} NewsCategory={props.NewsCategory}/>
                  }

                  {Modules.Background_Image !== null && Modules.Style !== 'Header_Banner' && renderComponent &&
                      <>
                          <FullVideo image={Modules.Background_Image} content={Modules.Overlay_Content ? Modules.Overlay_Content : 'Martyn Gerrard'} url={Modules.Embed_Video_URL} />
                      </>
                  }

                  {Modules.Intro_Title !== null &&
                      <>
                          <Managing Title={Modules.Intro_Title} Content={Modules.Intro_Content} People={Modules.Choose_People}/>
                          {props.Page.Alias === 'property-market-data' &&
                              <div className="property-market-data">
                                  <MarketData />
                              </div>
                          }
                      </>
                  }

                  {Modules.Choose_Style === 'Grid' &&
                      <StaticFeatures data={Modules.Add_Services} page={props.Page.Pagename}/>
                  }

                  {Modules.Select_Collection === 'News' &&
                      <div className="video-main-landing">
                          <News />
                      </div>
                  }

                  {Modules.Select_Collection === 'Latest_News' &&
                      <div className="video-main-landing latest-news-section">
                          <LatestNews />
                      </div>
                  }

                  {Modules.Select_Collection === 'Career_Videos' &&
                      <>
                          <CareerVideos />
                      </>
                  }

                  {Modules.Select_Collection === 'Awards' &&
                      <PartnersBrand />
                  }

                  {Modules.Select_Collection === 'History' &&
                      <History />
                  }

                  {Modules.Select_Collection === 'People' &&
                      <PeopleLanding />
                  }

                  {Modules.Select_Collection === 'Area_Guides' &&
                      <AreaGuideLanding />
                  }

                  {Modules.Select_Collection === 'All_Videos' &&
                      <VideosLanding AllMenus={props.AllMenus}/>
                  }

                  {Modules.Select_Collection === 'Careers' &&
                      <Jobs />
                  }

                  {Modules.Local_Love_Content &&
                      <LocalLove content={Modules.Local_Love_Content} image={Modules.Image} label={Modules.CTA_Label} link={Modules.CTA_URL}/>
                  }


                  { props.Page.Pagename === "Reviews" &&  cutomer_reviews &&
                      <>
                      <AllReviews values={cutomer_reviews}/>
                      <TrustPilotWidget />
                      <GoogleReviews />
                      </>
                  }
                  { Modules.Select_Collection === 'Customer_Reviews' &&
                        <ReviewList/>
                  }

                  {Modules.Place !== null &&
                      <FeaturedProperties place={Modules.Place} page={props.Page.all_menus ? props.Page.all_menus[0].id : ''} type={Modules.Search_Type} Department={Modules?.Department} tag="landing"/>
                  }

                  {Modules.Slide !== null &&
                      <MarketProperty slider={Modules.Slide}/>
                  }


                  {Modules.Choose_Category_to_Show !== null &&
                      <div className="video-main-landing">
                          <VideoCategory category={Modules.Choose_Category_to_Show}/>
                      </div>
                  }

                  {Modules.Link !== null &&
                      <CanHelpStyleTwo links={Modules.Link} title={Modules.Title} menus={props.AllMenus}/>
                  }

                  {Modules.Select_Form === 'Contact' &&

                      <div className="event-section separate-form-page">
                          <div className="reach-us-pages">
                              <div className="valuation-banner">
                                <picture>
                                  <source media="(min-width:1699px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                                  <source media="(min-width:992px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                                  <source media="(min-width:768px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                                  <img loading="lazy" className="bg-img" src={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} alt="BannerImg" />
                                </picture>
                              </div>
                              <div className="event-wrap main-event-wrap">
                              <Breadcrumbs id={props.Page.all_menus ? props.Page.all_menus[0].id : ''} parent="contact" tag="market-property" contact_parent="" />
                              <div class="event-title"><ReactMarkdown source={Modules.Form_Heading} allowDangerousHtml /></div>
                                  <ReachUsForm  />
                              </div>
                          </div>
                      </div>

                  }
                   {Modules.Select_Form === 'Registration' &&

                        <div className="event-section separate-form-page">
                            <div className="registration-pages">
                                <div className="valuation-banner">
                                <picture>
                                    <source media="(min-width:1699px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                                    <source media="(min-width:992px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                                    <source media="(min-width:768px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                                    <img loading="lazy" className="bg-img" src={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} alt="BannerImg" />
                                </picture>
                                </div>
                                <div className="event-wrap main-event-wrap">                               
                                <div class="event-title"><ReactMarkdown source={Modules.Form_Heading} allowDangerousHtml /></div>
                                    <RegistrationFormPage  />
                                </div>
                            </div>
                        </div>

                    }

                {Modules.Select_Form === 'MG_VIP_Form' &&

                <div className="event-section separate-form-page MgVipForm_background">
                    <div className="reach-us-pages">
                        <div className="valuation-banner">
                        <picture>
                            <source media="(min-width:1699px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                            <source media="(min-width:992px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                            <source media="(min-width:768px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                            <img loading="lazy" className="bg-img" src={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} alt="BannerImg" />
                        </picture>
                        </div>
                        <div className="event-wrap main-event-wrap">
                        <Breadcrumbs id={props.Page.all_menus ? props.Page.all_menus[0].id : ''} parent="contact" tag="market-property" contact_parent="" />
                        <div class="event-title"><ReactMarkdown source={Modules.Form_Heading} allowDangerousHtml /></div>
                            <MgVipForm />
                        </div>
                    </div>
                </div>

                }


                  {Modules.Select_Form === 'Local_Recommendation' &&

                      <div className="event-section separate-form-page">
                          <div className="reach-us-pages">
                              <div className="valuation-banner">
                                <picture>
                                  <source media="(min-width:1699px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                                  <source media="(min-width:992px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                                  <source media="(min-width:768px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                                  <img loading="lazy" className="bg-img" src={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} alt="BannerImg" />
                                </picture>
                              </div>
                              <div className="event-wrap main-event-wrap">
                              <Breadcrumbs id={props.Page.all_menus ? props.Page.all_menus[0].id : ''} parent="contact" tag="market-property" contact_parent="" />
                              <div class="event-title"><ReactMarkdown source={Modules.Form_Heading} allowDangerousHtml /></div>
                                  <RecommendationForm Alllocallife={props.Alllocallife} AllAreas={props.AllAreas} />
                              </div>
                          </div>
                      </div>

                    }

                    {Modules.Select_Form === 'Coupon_Code_Form' &&

                    <div className="event-section separate-form-page">
                        <div className="reach-us-pages">
                            <div className="valuation-banner">
                            <picture>
                                <source media="(min-width:1699px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                                <source media="(min-width:992px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                                <source media="(min-width:768px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                                <img loading="lazy" className="bg-img" src={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} alt="BannerImg" />
                            </picture>
                            </div>
                            <div className="event-wrap main-event-wrap">
                            <Breadcrumbs id={props.Page.all_menus ? props.Page.all_menus[0].id : ''} parent="contact" tag="market-property" contact_parent="" />
                            <div class="event-title">{ !headingToggle && <ReactMarkdown source={Modules.Form_Heading} allowDangerousHtml /> }</div>
                                <CouponCodeForm setHeadingToggle={setHeadingToggle} Alllocallife={props.Alllocallife} AllAreas={props.AllAreas} />
                            </div>
                        </div>
                    </div>

                    }
                    {Modules.Select_Form === 'Make_Payment' &&

                    <div className="event-section separate-form-page make-payment-page" style={{ backgroundImage: `url(${Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg}` }}>
                        <div className="reach-us-pages">
                            {/* <div className="valuation-banner">
                            <picture>
                                <source media="(min-width:1699px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                                <source media="(min-width:992px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                                <source media="(min-width:768px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                                <img loading="lazy" className="bg-img" src={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} alt="BannerImg" />
                            </picture>
                            </div> */}
                            <div className="event-wrap main-event-wrap">
                            <Breadcrumbs id={props.Page.all_menus ? props.Page.all_menus[0].id : ''} parent="contact" tag="market-property" contact_parent="" />
                            <div class="event-title"><ReactMarkdown source={Modules.Form_Heading} allowDangerousHtml /></div>
                                <MakeaPayment />
                            </div>
                        </div>
                    </div>

                    }

                    {Modules.Select_Form === 'Make_Payment_Success' &&
                    <div className="event-section separate-form-page make-payment-page" style={{ backgroundImage: `url(${Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg}` }}>
                        <div className="reach-us-pages">
                            <div className="event-wrap main-event-wrap">
                                <BreadcrumbsPayment />
                            <div class="event-title"><ReactMarkdown source={Modules.Form_Heading} allowDangerousHtml /></div>
                                <MakeaPaymentSuccess />
                            </div>
                        </div>
                    </div>
                    }

                    {Modules.Select_Form === 'Make_Payment_Failure' &&
                    <div className="event-section separate-form-page make-payment-page" style={{ backgroundImage: `url(${Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg}` }}>
                        <div className="reach-us-pages">
                            <div className="event-wrap main-event-wrap">
                                <BreadcrumbsPayment />
                            <div class="event-title"><ReactMarkdown source={Modules.Form_Heading} allowDangerousHtml /></div>
                                <MakeaPaymentFailure />
                            </div>
                        </div>
                    </div>
                    }

                  {Modules.Select_Form === "Career" &&


                      <div className="event-section separate-form-page career-submission-form">
                          <div className="reach-us-pages">
                              <div className="valuation-banner">
                                <picture>
                                  <source media="(min-width:1699px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                                  <source media="(min-width:992px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                                  <source media="(min-width:768px)" srcset={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} />
                                  <img loading="lazy" className="bg-img" src={Modules.Form_Background_Image ? Modules.Form_Background_Image.childImageSharp.gatsbyImageData.images.fallback.src : Bg} alt="BannerImg" />
                                </picture>
                              </div>
                              <div className="event-wrap main-event-wrap">
                              <Breadcrumbs id={props.Page.all_menus ? props.Page.all_menus[0].id : ''} parent="contact" tag="market-property" contact_parent="" />
                              <div class="event-title"><ReactMarkdown source={Modules.Form_Heading} allowDangerousHtml /></div>
                                  <CareerForm Modules={props.Modules} classone="career-form form stbform valuation-main recommendation-form"/>
                              </div>
                          </div>
                      </div>


                  }

                  {Modules.Sticky_Content &&
                      <CanHelpStyleFour Sticky_Content={Modules.Sticky_Content} Sticky_CTA_1_Label={Modules.Sticky_CTA_1_Label} Sticky_CTA_2_Label={Modules.Sticky_CTA_2_Label} Sticky_CTA_1_Link={Modules.Sticky_CTA_1_Link} Sticky_CTA_2_Link={Modules.Sticky_CTA_2_Link} Alignment={Modules.Alignment}/>
                  }

              </div>
          );
        })}
        </>
        }

        {props.Page.Show_How_Can_We_Help !== false &&
            <>
            <CanHelp />
            </>
        }
    </>;
}

export default Modules