import React,{useState, useEffect} from 'react'
import { Link } from "@StarberryUtils";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
//import ScrollAnimation from 'react-animate-on-scroll';
import {Col, Row, Container } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
import VideoPlay from "./Play/custom-video";
import logoBlack from "../images/mg-logo-black.svg";

const GET_ALL_JOBS = gql`
  query GetJobs{	
	  careers(sort:"createdAt:asc")  {
	    Title
	    URL
	    Tile_Image {
	      url
	    }
	    Short_Description
	    Embed_Video_URL
      }
  }
`;


function AllJobs() {

	const { loading, error, data } = useQuery(GET_ALL_JOBS);

  if (loading) return (
    <section className={"loader-wrapper"}>
  <div id="loader-wrapper">
    <div id="loader" class="new-loader">
    <div className="new-loader-anime"></div>
    <img loading="lazy" className="logo-white" src={logoBlack} className="loader-logo" alt="logo"/>
    </div>
    <div class="loader-section section-left"></div>
    <div class="loader-section section-right"></div>
  </div>
  </section>);
  	return (
  		<Container>
  		<Row>
			<div className="jobs-listing-page">
        {data.careers.map((jobs, i) => {
          return (
                <div className="office-box jobs-box news-card">
                {jobs.Tile_Image ?
                    <div className="office-box-img news-img">                    
                    <figure>
                    {
                        i==0 ? 
                        <picture>
                        <source media="(min-width:768px)" srcset={`${jobs.Tile_Image.url}`} />
                        <img loading="lazy"  src={`${jobs.Tile_Image.url}`} alt="BannerImg" />
                        </picture>
                        :<React.Fragment>
                            <img loading="lazy" src={`${jobs.Tile_Image.url}`} alt="" className="d-none d-sm-block"/>
                            <img loading="lazy" src={`${jobs.Tile_Image.url}`} alt="" className="d-block d-sm-none" />    
                        </React.Fragment>
                    }
					{jobs.Embed_Video_URL ? <VideoPlay url={jobs.Embed_Video_URL}/> : '' } 
                    </figure>                    
                    </div>
                    : "" }
                    <Link to={jobs.URL}><div className="office-info">
                        <div className="office-name d-flex align-items-center">
                        <strong>{jobs.Title}</strong>
                        <i className="arrow-icon ml-auto"></i>
                        </div>
                        <div className="office-address">
                        	<ReactMarkdown source={jobs.Short_Description} allowDangerousHtml />
                        </div>
                    </div></Link>
                </div>
          )
        })}

		    </div>
  		</Row>
  		</Container>
  	)
}



export default AllJobs
