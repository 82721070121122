import { Link } from "@StarberryUtils";
import React,{useEffect, useState, useRef} from "react";
import { Modal } from "react-bootstrap"
import ModalVideo from 'react-modal-video'
import 'react-modal-video/scss/modal-video.scss';
import { createPortal } from 'react-dom';
import { getSrc } from "gatsby-plugin-image"
import ReactPlayer from 'react-player'
import { useLocation } from "@reach/router"
import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile
} from "react-device-detect";

import "../VideoCard/VideoCard.scss"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";


const ModalWindow = ({ children }) => {
    // element to which the modal will be rendered
    const el = document.createElement("div");
    // add a class to the container div for styling
    // el.className = "modal-portal-container";
    useEffect(() => {
        const modalRoot = document.body;
        // append to root when the children of Modal are mounted
        modalRoot.appendChild(el);
        // do a cleanup
        return () => {
            modalRoot.removeChild(el);
        };
    }, [el]);

    return(
        createPortal(
            children,
            el
        )
    )
}



const PeopleTile=(props)=>{

    const [isOpen, setOpen] = useState(false);   

     const trackerVideo = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Video Play Btn',
          'formType': event,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
        });
    }

    let image = props.image ? getSrc(props.image) : ''

    const [isPlay, setPlay] = useState(false);
    const vidRef = useRef(null);
    const [show, setShow] = useState(false);
    const [play, setPlayvideo] = useState(true);
    const [mute, setMute] = useState(true);
    const [controls, setVideoControls] = useState(false);
    
    
    const [showHTMLVideo, setHTMLVideo] = React.useState(false);
    const pausevideo = (ref) => {
      setPlayvideo(false)
      setVideoControls(false)
    
    }
    const playvideo = (ref) => {
      setPlayvideo(true)
      setVideoControls(false)
    }
    const showControls = () => {
      setVideoControls(true)
    }
    
  return(
          <>
            
              <div className="video-card list-tile">
              <div className="people-tile-image-link">
                <Link to={props.url}><figure className="video-img">

                  <img loading="lazy" src={image} alt={`${props.videoTitle} + ' - Martyn Gerrard'`} />

                  </figure></Link>
                  
                  {props.video_url ? <strong className="play-btn people-landing-play" onClick={() => { setHTMLVideo(true); playvideo(); setShow(true); }}><i className="icon-play-transparent"></i></strong> : '' }
                </div>
                <Link to={props.url}><strong className="video-title d-block">{props.videoTitle+" "}
                <span className="membership">
                  {props.membership}
                </span>
                </strong>
                <p className="schedule">{props.schedule}</p></Link>
                {props.contactNo ? <Link href={`tel:+${props.contactNo}`}><span className="contact-no">{props.contactNo}</span></Link> : '' }
                
              </div>
            
              {showHTMLVideo  &&
                <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-video"
                aria-labelledby="example-custom-modal-styling-title"
                backdropClassName="video-backdrop"
                className="area-guide-full-video-popup"
                >
                <Modal.Header closeButton>
                </Modal.Header>
                    <Modal.Body>

                    <ReactPlayer frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen autoplay ref={vidRef} onPlay={trackerVideo(props.videoTitle)} url={props.video_url} modestbranding="1"  controls={isMobile && isIOS ?true:false} autoplay={true} muted={isMobile && isIOS ? mute : false} playsinline={true} playing={play} onEnded={() => { setHTMLVideo(false) }} width='100%' height='100%' />
                 
                    </Modal.Body>
                </Modal>

            }
          </>
)
  }

export default PeopleTile
