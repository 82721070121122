import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from "gatsby";
import Video from "../../Play/custom-video";
import  "../Reviews/Reviews.scss";
import {Col, Row, Container, Button } from 'react-bootstrap';
import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerItem = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      }
    }
  }
  const contentItem = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 }
  }
  

const ReviewList = () => {


    const [videoList, setVideoList] = useState([])
    const [count, setCount] = useState(24)

    const data = useStaticQuery(graphql`{
        allStrapiCustomerReviews(
            filter: {Publish: {eq: true}}
            sort: {fields: published_at, order: DESC}
        ) {
            edges {
            node {
                id
                Name
                Embed_Video_URL
                Image {
                childImageSharp {
                    gatsbyImageData(quality: 100, width: 288, layout: CONSTRAINED)
                }
                }
                strapiId
            }
            }
        }
        }
    `);

    const arr =  data.allStrapiCustomerReviews.edges
    var finalList = arr.slice(0,count)


    const hanldeLoadMore = () => {
        setCount(count + 8)
        var multiple = count + 8
        finalList = arr.slice(0, multiple)
        setVideoList(finalList)
    }

    useEffect(() => {
        setVideoList(finalList)
     
    }, [])
      
  return (
    <div className='video_testimonial_wrapper'>
        <div className='video_testimonial_container'>
        <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.section
            className="customers-reviews components-Home-Reviews-Reviews desktop-main-list"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerItem}
          >
            <Container>
                <div className='video_testimonial_list'>
                    {videoList.map(({node}, i) => {
                        return (
                        <motion.li key={i} variants={contentItem} className="video_test_card">
                            <div className={`sub-list id-${i}`}>
                            <div className="review-video">
                                <img loading="lazy" src={node?.Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} alt={`${node?.Name} - Martyn Gerrard`} />
                                <Video title={`${node?.Name} Review`} url={node?.Embed_Video_URL}/>
                            </div>
                            <div className="video-name">{node?.Name}</div>
                            </div>
                        </motion.li>
                        );
                    })}
                </div>
                {
                    videoList.length < arr.length && <Button onClick={hanldeLoadMore} className="load_more_btn" >Load More</Button>
                }
                
            </Container>
            </motion.section>
        )}
      </InView>
        </div>
    </div>
  )
}

export default ReviewList