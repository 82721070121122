import React,{useEffect, useState} from "react";
import Slider from 'react-slick';
import {Col, Row, Container } from 'react-bootstrap';
import { Link } from "@StarberryUtils";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import logoBlack from "../../images/mg-logo-black.svg";
import { NewsDate } from "../common/site/functions";

const GET_ALL_NEWS = gql`
  query GetNews{
	  propertyNews(sort:"Date:desc",limit:3)  {
	    Title
	    URL
	    Image_Type
	    Tile_Image {
	      url
	    }
	    Date
	    Category {
	      Name
	      URL
	    }
	    id
	    imagetransforms
      }
  }
`;

const settings =  {
  dots: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  infinite: true,
  arrows: false,

  autoplay: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}


function AllNews() {

	const { loading, error, data } = useQuery(GET_ALL_NEWS);

  	if (loading) return (

		<section className={"loader-wrapper"}>
		<div id="loader-wrapper">
		  <div id="loader" class="new-loader">
			<div className="new-loader-anime"></div>
			<img loading="lazy" className="logo-white" src={logoBlack} className="loader-logo" alt="logo"/>
		  </div>
		  <div class="loader-section section-left"></div>
		  <div class="loader-section section-right"></div>
		</div>
	  </section>
	  );
  	return (
  		<>
		<div className="news-page">
            <section className="case-studies related-news components-Home-CaseStudies-related-news">
              <Container>
                <Row>
                  <Col md={12}>
                  <Slider className="study-slider"  {...settings} >
                  {data.propertyNews.map((news, i) => {
                    return (
                          <Link to={`/news-and-guides/property-news/${news.Category[0].URL}/${news.URL}`}><div className="started-details">
                            <figure className="study-img"><img loading="lazy" src={`${news.Tile_Image.url}`} alt={`${news.Title} - Martyn Gerrard`} /></figure>
                            <p className="schedule">{NewsDate(news.Date)}</p>
                            <strong className="study-title d-block">{news.Title}</strong>
                          </div></Link>
                    )
                  })}
                  </Slider>
                  </Col>
                </Row>
              </Container>
            </section>
	    </div>
  		</>
  	)
}

export default AllNews
