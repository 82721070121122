import React,{useState, useEffect} from 'react'
import { Link } from "@StarberryUtils";
import { useStaticQuery, graphql } from "gatsby";
import VideoPost from "./NewsPost/VideoPost"
import _ from "lodash"


const VideosCategory = (props) => {

var videos = []

  const data = useStaticQuery(graphql`
    query{
  	  allStrapiVideos(sort: {order: DESC, fields: Date}, filter: {Publish: {eq: true}}) {
		edges {
		  node {
			id
			Title
			Date
			URL
			description
			Embed_Video_URL
			Meta_Description
			Meta_Title
			Tile_Image {
			  internal {
				description
			  }
			}
			imagetransforms {
			  Tile_Image_Transforms
			}
			Video_Category {
			  Name
			  URL
			  id
			}
			Videos_Parent_Category {
			  URL
			}
		  }
		}
	  }
    }
  `);

  videos = data.allStrapiVideos.edges.filter(item => {
	let main_category = item.node.Video_Category.filter(cat => _.includes(cat, props.category.id));
	return main_category.length > 0
 });

 if(videos) {
	videos.map((item, i) => {
		// console.log("video",item)
		videos[i] = item.node
	})

	//  videos[0] = videos[0].node
 }



//   let videos = data.allStrapiVideos.edges.filter(item => item.node.strapiId === props.category.id).pop().node;


//  console.log("videos1",JSON.parse(videos))
	return (
		<>
			<VideoPost data={videos} url_tag="land"/>
		</>
	)
}


export default VideosCategory