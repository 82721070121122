import React from "react"
import { Link } from "@StarberryUtils";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"

import { GetURL } from "../common/site/functions";


const GET_ALL_PEOPLES = gql`
  query GetNews{	 
	  people(sort: "createdAt:desc", where: { Publish: true }) {
	    Name
	    Tile_Image {
	      url
	    }
	    Designation
	    URL
	  }
  }
`;


function AllPeople() {

	const { loading, error, data } = useQuery(GET_ALL_PEOPLES);

  	if (loading) return <p>Loading ...</p>;
  	return (
  		<>
  			{data.people.map((node, index) => {					
				return (
					<Link to={node.URL}>
						{node.Name}
						<img loading="lazy" src={`https://martyngerrard-strapi.q.starberry.com${node.Tile_Image.url}`} alt={node.Name} />
						{node.Designation}
					</Link>
				)
			})}

  		</>
  	)
}



export default AllPeople
