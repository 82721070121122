import { Link } from "@StarberryUtils";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Col, Row, Container } from 'react-bootstrap';
// import VideoCard from '../VideoCard/VideoCard';
import "./OfficeCard.scss";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const contentItem = {
    hidden: { opacity: 0, x: -30 },
    visible: (custom) => ({
        opacity: 1,
        x: 0,
        transition: {
            delay: custom * 0.1,
            duration: 0.4,
            ease: [0.33, 1, 0.68, 1],
        }
    })
  }

function OfficeCardFilter(props) {


    const data = props.offices.edges

    var count = ''

    var office_list = [];
    for(var i=0; i < data.length; i++){
        let title = data[i].node.Name.toLowerCase()
        let titlec = data[i].node.Name.toUpperCase()
        let areas = data[i].node.Areas ? data[i].node.Areas.toLowerCase() : ''
        let areasc = data[i].node.Areas ? data[i].node.Areas.toUpperCase() : ''
        let address = data[i].node.Address ? data[i].node.Address.toLowerCase() : ''
        let addressc = data[i].node.Address ? data[i].node.Address.toUpperCase() : ''
        let postcodes = data[i].node.Postcode ? data[i].node.Postcode.toLowerCase() : ''
        let postcodesc = data[i].node.Postcode ? data[i].node.Postcode.toUpperCase() : ''
        if(title.includes(props.text) || titlec.includes(props.text)) {
            office_list.push(data[i])
        }
        else if(address.includes(props.text.toLowerCase()) || addressc.includes(props.text)) {
            office_list.push(data[i])
        }
        else if(areas.includes(props.text.toLowerCase()) || areasc.includes(props.text)) {
            office_list.push(data[i])
        }
        else if(postcodes.includes(props.text.toLowerCase()) || postcodesc.includes(props.text)) {
            office_list.push(data[i])
        }
    }

    if(office_list.length == 0) {
        count = 'empty'
        office_list = data
    }
    else {
        count = ''
    }


    return (
        <section className="office-list">
        {count == 'empty' &&

        <div className="news-posts" >
            <Container>
                <Row> 
                    <Col lg={12}>
                        <div className="no-match-news">
                            Unfortunately, we do not currently have any offices that match your search criteria.
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        }
            {
                office_list.map(({ node }, i) => {

                    const image_url = node.Tile_Image.internal.description ? node.Tile_Image.internal.description.replace("File ", "").replace('"', '').replace('"', '') : '';

                    let processedImages = JSON.stringify({});
                    if (node?.imagetransforms?.Tile_Image_Transforms) {
                        processedImages = node.imagetransforms.Tile_Image_Transforms;
                    }

                    return (
                        <InView threshold={0.2} key={i}>
                            {({ ref, inView }) => (
                                <motion.div
                                    ref={ref}
                                    initial="hidden"
                                    animate={inView ? "visible" : "hidden"}
                                    variants={contentItem}
                                    custom={i}
                                >
                                    <Link to={node.URL}>
                                        <div className="office-box" id={node.id}>
                                            <div className="office-box-img">
                                                <figure>
                                                    {
                                                        i == 0 ?
                                                            <picture>

                                                                <img loading="lazy" src={image_url} alt={`${node.Name} - Martyn Gerrard`} />

                                                            </picture>
                                                            : <React.Fragment>

                                                                <img loading="lazy" src={image_url} alt={`${node.Name} - Martyn Gerrard`} />

                                                            </React.Fragment>
                                                    }
                                                </figure>
                                            </div>
                                            <div className="office-info">
                                                <div className="office-name d-flex align-items-center">
                                                    <strong>{node.Name}</strong>
                                                    <i className="arrow-icon ml-auto"></i>
                                                </div>
                                                <div className="office-address">
                                                    <span className="phone">{node.Phone}</span>
                                                    <span>{node.Address}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </motion.div>
                            )}
                        </InView>
                    )
                })
            }

        </section>
    )
}

export default OfficeCardFilter
