import React, { useState, useEffect } from "react";
import { Link } from "@StarberryUtils";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"

import { LATEST_NEWS_PAGE_URL } from "../common/site/constants";

const GET_NEWS_MENUS = gql`

query GetNewsMenus {
  newsCategories {
      Name
      URL
    }
}
`;

function NewsCategories() {
if (typeof window !== "undefined") {
    var pageURL = window.location.href ? window.location.href : '';
    var removeSlash = pageURL.replace(/^(.+?)\/*?$/, "$1");
    var lastURLSegment = removeSlash.substr(removeSlash.lastIndexOf('/') + 1);
}

const { loading, error, data } = useQuery(GET_NEWS_MENUS);

  if (loading) return <p>Loading ...</p>;
  return (
  	<>

        <ul className="category-list d-flex">
			<li>
				<Link className="active" to={`${LATEST_NEWS_PAGE_URL.alias}`}>all</Link>
			</li>
			{data.newsCategories.map((news, index, active) => {
			return (
			<li>
			<Link className = {(lastURLSegment == news.URL) ? "active" : ""} to={`${LATEST_NEWS_PAGE_URL.alias}/${news.URL}`}>{news.Name}</Link>
			</li>
			)
			})}

		</ul>
		</>
    );
}


export default NewsCategories
