import React from 'react'
import {Col, Row, Container } from 'react-bootstrap';
import VideoPlay from "../Play/custom-video";


const VideoTiles = (props) => {
	return (
        <div className="video-tiles components--Static--video-tiles news-card career-video-no-link">
			<Container>
				<Row>
		        {props.videos.map((video, i) => {
		          return (
                      <Col md={4}>
                          <div className="tile">
                              <figure>
                                  <img loading="lazy" src={video.Background_Image.childImageSharp.gatsbyImageData.images.fallback.src} alt="" />
                              </figure>
                              {video.Embed_Video_URL ? <VideoPlay url={video.Embed_Video_URL}/> : '' }
                              <div className="title">{video.Content}</div>
                          </div>
                      </Col>
                  );
				})}
				</Row>
			</Container>
		</div>
    );
}

export default VideoTiles
