// import { Link } from "@StarberryUtils";
// import PropTypes from "prop-types";
import React,{useEffect, useState} from "react";
import {Col, Row, Container } from 'react-bootstrap';
import  "./PartnersBrand.scss";
// import partnerBrand01 from "../../../images/partner-brand01.jpg";
// import partnerBrand02 from "../../../images/partner-brand02.jpg";
// import partnerBrand03 from "../../../images/partner-brand03.jpg";
// import partnerBrand04 from "../../../images/partner-brand04.jpg";
import { useStaticQuery, graphql } from "gatsby";
import MarkerSlide from "../MarkerSlide/MarkerSlide";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerItem = {
    hidden: { opacity: 0, y: 50, scale: 0.8 },
    visible: {
      opacity: 1,
	  y: 0,
	  scale: 1,
      transition: {
        ease: [0.33, 1, 0.68, 1],
        duration: 0.8
      }
    }
}
const MotionCol = motion(Col)

const PartnersBrand=(props)=>{

  const [scroll, setScroll] = useState(false);
  
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 150)
    })
  }, [])

  const data = useStaticQuery(graphql`
    query{
    allStrapiAwards(filter: {Publish: {eq: true}}) {
        edges {
          node {
            Title
            Choose_Year {
              Year
            }
            Image {
              internal {
                description
              }
            }
            strapiId
            imagetransforms {
              Image_Transforms
            }
          }
        }
      }

    allStrapiAwardYears(filter: {Publish: {eq: true}}, sort: {order: DESC, fields: Year}) {
        edges {
          node {
            Year
          }
        }
      }

    }
  `);


  return(
  <section className="partners-brands text-center awards-list">
  
  <div className={scroll ? 'fixed-marker-awards' : ''}>
    <MarkerSlide />
  </div>

    <Container>
        {data.allStrapiAwardYears.edges.map(({node}, i) => {
          let year = node.Year

          return (
            <div id={year}>
                <h2 className="sec-title text-center">{node.Year}</h2>
                <Row>
                  {data.allStrapiAwards.edges.map(({node}, i) => {

          const image_url = node.Image.internal.description ? node.Image.internal.description.replace("File ","").replace('"','').replace('"','') : ''; 

          let processedImages = JSON.stringify({});
          if (node?.imagetransforms?.Image_Transforms) {
              processedImages = node.imagetransforms.Image_Transforms;
          }
                    return (
                      <InView {...inViewOptions}>
							          {({ ref, inView }) => (
                          <>
                            {node.Choose_Year.Year === year &&
                              <MotionCol
                                md={4}
                                lg={3}
                                ref={ref}
                                initial="hidden"
                                animate={inView ? "visible" : "hidden"}
                                variants={containerItem}
                              >
                                  <div className="partner">
                                      <figure>
                                      <ImageTransform imagesources={image_url} renderer="srcSet" imagename="awards.Image.tile" attr={{ alt: node.Title+ ' - Martyn Gerrard', class:'' }} imagetransformresult={processedImages} id={node.strapiId}/>
                                      </figure>
                                      <p className="desc">{node.Title}</p>
                                  </div>
                              </MotionCol>
                            }
                          </>
                        )}
                      </InView>
                    )
                  })}
                </Row>
            </div>
          )
        })}
    
    </Container>
  </section>
)
  }

export default PartnersBrand
