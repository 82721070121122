import React,{useEffect, useState} from "react";
import {Col, Row, Container,Navbar,Nav } from 'react-bootstrap';
import Select from "react-select";
import  "../MarketData/MarketData.scss";

const MarketData=(props)=>{

    const [iFrameHeight, setIFrameHeight] = useState('0px')
    const ref = React.useRef();
    useEffect(() => {
      const timer = setTimeout(() => {
        onLoad();
      }, 1000);
      return () => clearTimeout(timer);
    }, []);
    const onLoad = () => {
      setIFrameHeight(ref.current.contentWindow.document.body.scrollHeight + "px");
    };

    const [office, setOffice] = useState ("barnet")
    const dataloft = [
      {label: "Barnet", value: "barnet"},
      {label: "Crouch End", value: "crouch-end"},
      {label: "Finchley Central", value: "finchley-central"},
      {label: "North Finchley", value: "north-finchley"},
      {label: "East Finchley", value: "east-finchley"},
      {label: "Hampstead", value: "hampstead"},
      {label: "Kentish Town", value: "kentish-town"},
      {label: "Muswell Hill", value: "muswell-hill"},
      {label: "Mill Hill", value: "mill-hill"},
      {label: "Southgate", value: "southgate"},
      {label: "Winchmore Hill", value: "winchmore-hill"},
      {label: "Whetstone", value: "whetstone"},
    ]

  return (
    <div className="news-page video-main-landing">
        <div className="news-head text-center news-head-filter">
          <Container>
            <Row className="align-items-center">
              <Col lg={12}>
                  <Navbar className="justify-content-center">
                    <Nav className="text-left">
                    {dataloft.map((item) => {
                      return (<a className={`nav-link people-filter-desktop ${item.value} ${item.value == office ? 'active' : ''}`} href="javascript:void(0);" onClick={()=>{setOffice(item.value)}}>{item.label}</a>)
                    })}
                    </Nav>
                  </Navbar>
                  <div className="people-filter-mobile">
                    <div className="select-group office-dropdown">
                    <Select
                        name="officelist"
                        options={dataloft}
                        value={dataloft.find(obj => obj.value === office)}
                        placeholder={office ? office : "Barnet" }
                        classNamePrefix={"select-opt"}
                        onChange={e => setOffice(e.value)}
                        isSearchable={false}
                        components={{ DropdownIndicator:() => <i className="icon icon-dropdown-arrow"></i>, IndicatorSeparator:() => null }}
                    />
                    </div>
                  </div>
                  <div className="dataloft-iframe">
                    <iframe
                      src={`/${office}.html`} id="dataloft-wid"
                      ref={ref}
                      onLoad={onLoad}
                      height={iFrameHeight}
                      scrolling="no"
                      frameBorder="0"
                      style={{
                        width: "100%",
                        overflow: "auto",
                    }}
                    ></iframe>
                  </div>
              </Col>
            </Row>
          </Container>
        </div>
    </div>
  )
  }

export default MarketData
