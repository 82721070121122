import { Link } from "@StarberryUtils";
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {Row, Col, Container,Breadcrumb } from 'react-bootstrap';
import  "./Breadcrumb.scss";
//import "animate.css/animate.min.css";

import { GetURL } from "../../common/site/functions";

const BreadcrumbsPayment =(props) => {

    return(
  <div className={`breadcrumb-wrp text-center market-property-breadcrumb  components--MgServices--Breadcrumb--Breadcrumb`} >
    <Container className="bc">
        <Row className={`align-items-center`}> 
        <Col lg={12}>
            <Breadcrumb>
                <Breadcrumb.Item className="text-uppercase"><Link to="/">home</Link></Breadcrumb.Item>
                <Breadcrumb.Item className="text-uppercase"><Link to="/property-services/">Find a service</Link></Breadcrumb.Item>
                <Breadcrumb.Item className="text-uppercase"><Link to="/property-services/make-a-payment/">make a payment</Link></Breadcrumb.Item>
            </Breadcrumb>
        </Col>
        </Row>
    </Container>
  </div>
)
    }

export default BreadcrumbsPayment;

