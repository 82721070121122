import { Link } from "@StarberryUtils";
import React,{useState, useEffect} from "react";
import {Row, Col, Container,Navbar,Nav,NavDropdown,Form, Button } from 'react-bootstrap';
import BgShadow from "../../../images/bg-shadow.svg";
import  "./NewsHead.scss";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import $ from 'jquery'

import Search from "./search-form"

import { LATEST_NEWS_PAGE_URL } from "../../common/site/constants";

const GET_NEWS_MENUS = gql`

query GetNewsMenus {
  newsCategories(sort: "Sort:asc") {
      Name
      URL
    }


}
`;



function NewsHead(props) {

  const[addSearch,setAddSearch] = useState(false);

    const addingSearch=()=>{
      setAddSearch(!addSearch)
  }


    const  outSideLink= (e) => {
    if(!e.target.className.includes("icon-search-icon") && !e.target.className.includes('form-control')
     && !e.target.className.includes('search-field d-flex align-items-center') ) {
      setAddSearch(addSearch)
    }
      }


    useEffect(()=>{
      document.addEventListener('click',outSideLink)
    },[])

    if (typeof window !== "undefined") {
        var pageURL = window.location.href ? window.location.href : '';
        var removeSlash = pageURL.replace(/^(.+?)\/*?$/, "$1");
        var lastURLSegment = removeSlash.substr(removeSlash.lastIndexOf('/') + 1);
    }

    const { loading, error, data } = useQuery(GET_NEWS_MENUS);


    if (loading) return <p>Loading ...</p>;
   
    return(
  <div className="news-head text-center components-News-NewsHead-NewsHead news-head-filter">
    <Container>
        <Row className="align-items-center"> 
        <Col lg={12}>
            <Navbar className="justify-content-center">
              <Nav className="text-center">
                <Link to={`${LATEST_NEWS_PAGE_URL.alias}`} className={`nav-link people-filter-desktop ${props.tag === 'All News' ? 'active' : ''}`}>All News</Link>

                {data.newsCategories.map((news, index, active) => {
                return (
                  <Link to={`${LATEST_NEWS_PAGE_URL.alias}/${news.URL}`} className={`nav-link people-filter-desktop ${props.tag === news.Name ? 'active' : ''}`} key={index}>{news.Name}</Link>
                )
                })}

                <NavDropdown title={props.tag} id="nav-dropdown office-name-dropdown-title" className="people-office-menu-head people-listing-office-list people-filter-mobile"> 

                <NavDropdown.Item href={`${LATEST_NEWS_PAGE_URL.alias}`} className={`nav-link people-filter-mobile ${props.tag === 'All News' ? 'active' : ''}`}>All News</NavDropdown.Item>

                {data.newsCategories.map((news, index, active) => {
                return (
                <NavDropdown.Item href={`${LATEST_NEWS_PAGE_URL.alias}/${news.URL}`} eventKey={index+1} className={`nav-link people-filter-mobile ${props.tag === news.Name ? 'active' : ''}`} key={index} data-filter={news.URL}>{news.Name}
                </NavDropdown.Item>

                )
                })}
                </NavDropdown>


                <Nav.Link className="search-control" href="#"><i className="icon-search-icon" onClick={e=>addingSearch()}></i></Nav.Link>
                {
                  addSearch ?
                <Search handlechange={props.handlechange}/>
                :
                !addSearch
                }
              </Nav>
            </Navbar>
        </Col>
      </Row>
    </Container>
  </div>
)

}

export default NewsHead;