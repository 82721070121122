import { useStaticQuery, graphql } from "gatsby";
import React, {useEffect, useState} from "react";
import {Col, Row, Container, Button } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
import { Link } from "@StarberryUtils";
import headingBgImg from '../images/local_love_heading_bg_img.png'
import MgLocalLoveLogo from '../images/mg_logo_local.svg'
import axios from 'axios';
import { navigate, useLocation } from "@reach/router"
import ShowMoreText from 'react-show-more-text';
import ModalTiny from "react-bootstrap/Modal"
import CommentForm from "./forms/comments-reviews"
import LikeCommentBlock from './LocalLoveLikeCommentBlock'

const LocalLifeCategories=()=> {

    const data = useStaticQuery(graphql`{
      allStrapiLocalLifeComments(
        filter: {status: {eq: "Approved"}}
        limit: 99999
        sort: {fields: createdAt, order: DESC}
      ) {
        edges {
          node {
            name
            comments
            local_life_things_to_do {
              id
              URL
            }
          }
        }
      }
      allStrapiLocalLifeCategories(
        filter: {local_life_things_to_dos: {elemMatch: {Featured: {eq: true}}}, Publish: {eq: true}}
      ) {
        edges {
          node {
            Name
            URL
            Alias
            strapiId
            Intro_Content
            Meta_Description
            Meta_Title
            local_life_things_to_dos {
              area_guide
            }
          }
        }
      }
      allStrapiRestaurants(filter: {Publish: {eq: true}, Featured: {eq: true}}) {
        edges {
          node {
            Address
            Featured
            Name
            URL
            Tile_Image_Label
            Offer_MSG
            Short_Description
            Tile_Image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: CONSTRAINED)
              }
            }
            strapiId
            Category {
              Name  
              URL
              id
            }
          }
        }
  }
}
`);

  
    
    const list = data.allStrapiLocalLifeCategories.edges
    const reataurants = data.allStrapiRestaurants.edges
    const commites = data.allStrapiLocalLifeComments.edges

    // console.log(commites, "commites")

    const hanldeClick = (values) => {
      sessionStorage.setItem('BusinessName', values)
      navigate('/about/coupon-code/')
    }


      return (
        <div className="local-life-new-page-listing local-life-area-landing-page    ">
          
          

              {list.map(({node}, i) => {
                  let cat = node.URL
                  return <>
                    <Row>
                    
                      <Col md={12}>
                        <div className="__heading_block"  >
                          <img src={headingBgImg} alt="local love heading bg img"/>
                          <h2 className="sec-title">{node.Name}</h2>
                        </div>
                      </Col>
                      <Col md={10} className='listing-desc'>
                          <ReactMarkdown source={node.Intro_Content} allowDangerousHtml />
                      </Col>
                      <Col md={2}>
                          <div className="d-none d-md-block view-more">
                          <Link to={`${node.URL}`} className="btn btn-success local_love_view_more_btn">View More</Link>
                          </div>
                      </Col>
                  </Row> 
                  <div className="mg-favourites mg-fav-landing">
                  <div className="tab-content">
                  <Row className="mg-things-do-list">
                      {reataurants.map(({node}, i) => {
                          return <>
                              {node.Category && node.Category.URL == cat &&
                                  <Col md={6} xl={4}>
                                      <div className={`favourite-item no-border`}>
                                          <figure>
                                              {node.Tile_Image ? <Link to={`/about/loving-life/${cat}/${node.URL}`} className="link"><img loading="lazy" src={node.Tile_Image.childImageSharp.gatsbyImageData.images.fallback.src} alt={`${node.Name} - Martyn Gerrard`} /> </Link>: '' }
                                              {node.Tile_Image_Label ? <span className="tag-abs text-uppercase">{node.Tile_Image_Label}</span> : '' }
                                          </figure>
                                          { node.Featured && <img src={MgLocalLoveLogo} className="mg_logo_local_love" alt="logo"/>}
                                          <div className="info">
                                              <h4 className="info-title">{node.Name}</h4>
                                              <span className="add-txt">{node.Address?.substring(0, 50)} {node.Address?.length > 50 ? '...' : ''}</span>
                                              <LikeCommentBlock link={`/about/loving-life/${cat}/${node.URL}`} commites={commites} url={node.URL} strapiId={node.strapiId} name={node.Name} />
                                              {/* <ReactMarkdown source={node.Short_Description} allowDangerousHtml className=""/> */}
                                              <ShowMoreText
                                                  lines={4}
                                                  more='More'
                                                  less='Less'
                                                  className='desc-content'
                                                  anchorClass='btn-more review-more'
                                                  expanded={false}
                                                  keepNewLines={false}
                                                  >

                                                    {node.Short_Description}
                                                </ShowMoreText>
                                                <div className="business_offer_btn">
                                                  {
                                                    node.Offer_MSG && <Button onClick={()=> {hanldeClick(node.Name)}} className="btn btn-pink" type="submit">{node.Offer_MSG}</Button>
                                                  }
                                              </div>
                                          </div>
                                      </div>
                                  </Col>
                              }
                          </>;
                      })}
                       <Col className="d-block d-md-none">
                          <div className="view-more">
                          <Link to={`${node.URL}`} className="btn btn-success local_love_view_more_btn">View More</Link>
                          </div>
                      </Col>
                  </Row>
                  </div>
                  </div>
                  </>;
              })}
          <section className="fixed-btns d-flex d-md-none">
            <Link to="/about/local-recommendation/" className={`full-width-cta-person btn book-btn`} onClick={null}>Recommend a Business</Link>
            {/* <Link to="#" className="btn ask-btn btn-success" onClick={null}>Book an appointment</Link> */}
          
        </section>
          </div>
      );
    }
  export default LocalLifeCategories
