import { Link } from "@StarberryUtils";
import React from "react";
import {Row, Col, Container } from 'react-bootstrap';
import BgShadow from "../../../images/bg-shadow.svg";
import  "./ServiceHead.scss";
import ReactMarkdown from "react-markdown"
import Video from "../../Play/head-cta-video";

import { GetURL } from "../../common/site/functions";

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.8
    }
  }
}

const ServiceHead =(props) => {

    let cta_1_url = ''
    let cta_1 = ''
    let cta_2 = ''
    if(props.CTA_1_URL) {
        cta_1_url = GetURL(props.CTA_1_URL.id)

        var youtube_one  = cta_1_url.includes("youtube")
        var vimeo_one  = cta_1_url.includes("vimeo")
        if ((youtube_one == true) || (vimeo_one == true))
        { 
          cta_1 = 'video'
        }
    }
    let cta_2_url = ''
    if(props.CTA_1_URL) {
        cta_2_url = GetURL(props.CTA_2_URL.id)

        var youtube_two  = cta_2_url.includes("youtube")
        var vimeo_two  = cta_2_url.includes("vimeo")
        if ((youtube_two == true) || (vimeo_two == true))
        { 
          cta_2 = 'video'
        }
    }


    return(
      <InView threshold={0.2}>
        {({ ref, inView }) => (
          <motion.div
            className="mgservice-head text-center components--MgServives--ServiceHead--ServiceHead"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <Container>
                <Row className="align-items-center"> 
                <Col lg={12}>
                    <div className="service-details">
                        {props.Title ? <ReactMarkdown source={props.Title} unwrapDisallowed allowDangerousHtml /> : ''}

                        {/* <h2 className="sec-title">Explore our <span className="d-inline"> comprehensive<img loading="lazy" className="shadow-img" src={BgShadow} alt="underline"/></span> range of property services</h2> */}

                        <div className="exp-btns">
                            {props.CTA_1_Label ? 
                            <>
                            {cta_1 === 'video' ?
                            <Video class="btn d-block d-md-inline-block" label={props.CTA_1_Label} url={cta_1_url}/> : 
                            props.CTA_1_Email  ? <Link className="btn d-block d-md-inline-block" href={`/${cta_1_url}${props.CTA_1_Email ? `?mail=${props.CTA_1_Email}` : ``}`}>{props.CTA_1_Label}</Link> :
                            <Link className="btn d-block d-md-inline-block" to={`/${cta_1_url}`}>{props.CTA_1_Label}</Link>
                            }
                            </> : '' }

                            {props.CTA_2_Label ?
                            <>
                            {cta_2 === 'video' ?
                            <Video class="btn d-block d-md-inline-block" label={props.CTA_2_Label} url={cta_2_url}/> : 
                            props.CTA_2_Email ? <Link className="btn d-block d-md-inline-block" href={`/${cta_2_url}${props.CTA_2_Email ? `?mail=${props.CTA_2_Email}` : ``}`}>{props.CTA_2_Label}</Link> :
                            <Link className="btn d-block d-md-inline-block" to={`/${cta_2_url}`}>{props.CTA_2_Label}</Link>
                            }                        
                            </> : '' }
                        </div>
                    </div>
                </Col>
                </Row>
            </Container>
          </motion.div>
        )}
      </InView>
    )
}

export default ServiceHead;

