// import { Link } from "@StarberryUtils";
import React,{useState} from "react";
import {Button, Card, Accordion, Container } from 'react-bootstrap';
import  "./ServiceLinks.scss";

import GenerateLink from "../../common/site/generate-link";

import { inViewOptions } from '../../../components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}

const ServiceLinks =(props)=> {

    const [toggleActive, settoggleActive] = useState(null)
    const toggleClick = (event) => {
    if(toggleActive === event){
    settoggleActive(null)
    }
    else {
    settoggleActive(event)

    }
    
    }

    return(
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.div
            className="mgservices-links components--MgServives--ServiceLinks--ServiceLinks"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <Container>
                <Accordion>
                {props.links.Sub_Menus.map((links, i) => {
                  return (
                    <Card className={i === 6 ? "block-management" : ''} key={i}>
                        <Card.Header className={toggleActive === i+1 ? "minus" : ''}>
                        <Accordion.Toggle as={Button} onClick={e=>toggleClick(i+1)} variant="link" eventKey={i+1}>
                            {links.Parent_URL ?
                            <GenerateLink link={links.Parent_URL} class="text-white-bold" label={links.Parent_Label}>{links.Parent_Label}</GenerateLink> :
                            <span className="text-white-bold-no-line">{links.Parent_Label}</span>
                            }
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={i+1}>
                        <Card.Body>
                        <ul className="service-nav">
                            {links.Add_Sub_Menus.map((link, i) => {
                              return (
                                <li key={i}>
                                    <GenerateLink link={link.URL} class="" label={link.Label} >
                                        <span>{link.Label}</span>
                                    </GenerateLink>
                                </li>
                              )
                            })}
                        </ul>


                        {i === 3 ? <>
                        {props.links.Sub_Menus.slice(6).map((link, i) => {
                        return (
                          <>
                                            {link.Parent_Label === "Block Management" &&
                        <div className="block-management-desktop" key={i}>
                          <div className="btn">
                          <GenerateLink link={link.Parent_URL} class="text-white-bold" label={link.Parent_Label} >
                              {link.Parent_Label}
                          </GenerateLink>
                          </div>
                          <ul className="service-nav">
                            {link.Add_Sub_Menus.map((Add_Sub_Menus, i) => {
                              return <li>
                                  <GenerateLink link={Add_Sub_Menus.URL} class="" label={Add_Sub_Menus.Label} key={i}>
                                      <span>{Add_Sub_Menus.Label}</span>
                                  </GenerateLink>
                              </li>
                            })}
                          </ul>
                        </div>
                            }
                                          </>
                          )
                        })}
                        </> : ''}


                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                  )
                })}
                </Accordion>
            </Container>
          </motion.div>
        )}
      </InView>
  )
}
export default ServiceLinks;
