// import { Link } from "@StarberryUtils";
// import PropTypes from "prop-types";
import React from "react";
import {Col, Row, Container } from 'react-bootstrap';
import  "./StaticFeatures.scss";
// import partnerBrand01 from "../../../images/partner-brand01.jpg";
// import partnerBrand02 from "../../../images/partner-brand02.jpg";
// import partnerBrand03 from "../../../images/partner-brand03.jpg";
// import partnerBrand04 from "../../../images/partner-brand04.jpg";
// import Img from 'gatsby-image';
import { getSrc } from "gatsby-plugin-image"

import GenerateLink from "../../common/site/generate-link";

import { inViewOptions } from '../../../components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}

const items = {
  hidden: {
    opacity: 0,
    y: 40 
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.3
    }
  }
}
const MotionColumn = motion(Col)

const StaticFeatures=(props)=>{
  return(
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="static-features"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <Container>
            <Row className="static-features-bottom">
              {props.data.slice(0,4).map((item, i) => {
                return (
                  <MotionColumn
                    md={6}
                    lg={3}
                    className={`service-link${i === 3 ? ' d-md-block' : ''}`}
                    key={i}
                    variants={items}
                  >
                      <GenerateLink link={item.Choose_Link} class="ind-landing-tile">
                          {item.Background_Image ? <img loading="lazy" src={getSrc(item.Background_Image)} alt={`${props.page} ${item.Label} - Martyn Gerrard`} /> : '' }
                          <div className="feature d-flex justify-content-between">
                              <p className="desc">{item.Label}</p>
                              <i class="icon-lg-arrow-white"></i>
                          </div>
                      </GenerateLink>
                  </MotionColumn>
                )
              })}
            </Row>
            {props.data.length > 4 ? 
            <Row className="d-xl-flex row-2">
              {props.data.slice(4,8).map((item, i) => {
                return (
                  <MotionColumn
                    md={6}
                    lg={3}
                    className={`service-link${i === 3 ? ' d-md-block' : ''}`}
                    key={i}
                    variants={items}
                  >
                      <GenerateLink link={item.Choose_Link} class="ind-landing-tile">
                          {item.Background_Image ? <img loading="lazy" src={getSrc(item.Background_Image)} alt={`${props.page} ${item.Label} - Martyn Gerrard`} /> : '' }
                          <div className="feature d-flex justify-content-between">
                              <p className="desc">{item.Label}</p>
                              <i class="icon-lg-arrow-white"></i>
                          </div>
                      </GenerateLink>
                  </MotionColumn>
                )
              })}
            </Row> : '' }
            {props.data.length > 9 ? 
            <Row className="d-xl-flex row-3">
              {props.data.slice(8,12).map((item, i) => {
                return (
                  <MotionColumn
                    md={6}
                    lg={3}
                    className={`service-link${i === 3 ? ' d-md-block' : ''}`}
                    key={i}
                    variants={items}
                  >
                      <GenerateLink link={item.Choose_Link} class="ind-landing-tile">
                          {item.Background_Image ? <img loading="lazy" src={getSrc(item.Background_Image)} alt={`${props.page} ${item.Label} - Martyn Gerrard`} /> : '' }
                          <div className="feature d-flex justify-content-between">
                              <p className="desc">{item.Label}</p>
                              <i class="icon-lg-arrow-white"></i>
                          </div>
                      </GenerateLink>
                  </MotionColumn>
                )
              })}
            </Row> : '' }
            {props.data.length > 13 ? 
            <Row className="d-xl-flex row-3">
              {props.data.slice(12,16).map((item, i) => {
                return (
                  <MotionColumn
                    md={6}
                    lg={3}
                    className={`service-link${i === 3 ? ' d-md-block' : ''}`}
                    key={i}
                    variants={items}
                  >
                      <GenerateLink link={item.Choose_Link} class="ind-landing-tile">
                          {item.Background_Image ? <img loading="lazy" src={getSrc(item.Background_Image)} alt={`${props.page} ${item.Label} - Martyn Gerrard`} /> : '' }
                          <div className="feature d-flex justify-content-between">
                              <p className="desc">{item.Label}</p>
                              <i class="icon-lg-arrow-white"></i>
                          </div>
                      </GenerateLink>
                  </MotionColumn>
                )
              })}
            </Row> : '' }
          </Container>
        </motion.section>
      )}
    </InView>
  )
}

export default StaticFeatures
