import { Link } from "@StarberryUtils";
import React from "react";
import {Row, Container } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";

import CanHelp from "./Home/CanHelp/CanHelp"
import Breadcrumbs from "./MgServices/Breadcrumb/Breadcrumb"
import GenerateLink from "./common/site/generate-link";

import { inViewOptions } from './utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0,
    y: -30,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.8
    }
  }
}

const Sitemap = (props) => {
  const data = useStaticQuery(graphql`
    query{
    allStrapiMenuBurgers(filter: {Publish: {eq: true}}, sort: {fields: Sort, order: ASC}) {
        edges {
          node {
            Parent_Label
            Parent_URL {
              id
            }
            Sub_Menus {
              Parent_Label
              Parent_URL {
                id
              }
              Add_Sub_Menus {
                Label
                URL {
                  id
                }
              }
            }
          }
        }
    }


    }
  `);


	return (
			<div className="content">
        <InView {...inViewOptions}>
          {({ ref, inView }) => (
            <motion.section
              className="managing-sec managing-intro static-news-page sitemap"
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={containerVariants}
            >
              <div className="rental-market">
                <Breadcrumbs id={props.id}/>
                <Container>
                  <Row>
                  <div className="content">
                    <h1>Sitemap</h1>
                    <ul className="sitemap-main-list">
                      {data.allStrapiMenuBurgers.edges.map(({ node }, i) => {
                        var main_label = node.Parent_Label
                        return <React.Fragment key={i}>
                          {node.Sub_Menus.length === 0 &&
                              <li>
                              {node.Parent_URL ?
                              <GenerateLink link={node.Parent_URL} class="" label={node.Parent_Label}>{node.Parent_Label}</GenerateLink> :
                              <>{node.Parent_Label}</> 
                              }
                              </li>
                          }

                          {node.Sub_Menus.length !== 0 &&
                          <li>
                            {node.Parent_URL ?
                              <GenerateLink link={node.Parent_URL} class="" label={node.Parent_Label}>{node.Parent_Label}</GenerateLink>  :
                              <>{node.Parent_Label}</> 
                            }
                              <ul className="sub-menu sitemap-sub-menu-list">
                                {node.Sub_Menus.map((Sub_Menus, i) => {
                                  return <li key={i} className={main_label == "Find a Service" ? 'sub-li-list' : ''}>
                                    {Sub_Menus.Parent_URL ?
                                      <GenerateLink link={Sub_Menus.Parent_URL} class="" label={Sub_Menus.Parent_Label}>{Sub_Menus.Parent_Label}</GenerateLink> :
                                      <>{Sub_Menus.Parent_Label}</>
                                    }
                                    <ul className="level-2">
                                    {Sub_Menus.Add_Sub_Menus.map((Add_Sub_Menus, i) => {
                                      return <li key={i}>
                                        <GenerateLink link={Add_Sub_Menus.URL} class="" label={Add_Sub_Menus.Label}>{Add_Sub_Menus.Label}</GenerateLink>
                                      </li>
                                    })}
                                    </ul>
                                  </li>
                                })}
                                {main_label == "Find a Service" && i != '2' &&
                                  <li><Link to="/property-services/block-management">Block Management</Link></li>
                                }
                                  </ul>
                          </li>
                        }
                        </React.Fragment>
                      })}
                    </ul>

                </div>
                  </Row>
                </Container>
              </div>
            </motion.section>
          )}
        </InView>
			  <CanHelp />
		</div>
	)
}


export default Sitemap