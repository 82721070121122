import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react"
import Slider from "react-slick"
import { Row, Col, Container,Breadcrumb } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby";

import BannerImg376 from "../../../images/static-banner376-img.jpg"
import BannerImg768 from "../../../images/static-banner768-img.jpg"
import BannerImg from "../../../images/static-banner-img.jpg"
import GoogleReview from "../../../images/google-reviews-white.svg"
import "../../MgServices/Breadcrumb/Breadcrumb.scss"
import "./StaticBanner.scss"
import ReactMarkdown from "react-markdown"
import Breadcrumbs from "../../MgServices/Breadcrumb/Breadcrumb"
import LocalLifeCategories from "./local-life-categories"
import { GatsbyImage } from "gatsby-plugin-image";
import { getSrc } from "gatsby-plugin-image"

import { GetURL } from "../../common/site/functions";
import Video from "../../Play/head-cta-video";

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}

const imageVariants = {
  hidden: {
    opacity: 0,
    scale: 1.2 
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 40 
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.8
    }
  }
}

const StaticBanner = (props) => {

  const latestReviewsScore = useStaticQuery(graphql`
        query {
            allStrapiGoogleReviewReviews {
                edges {
                    node {
                        id
                        starRating
                    }
                },
                pageInfo {
                    totalCount
                }
            }
        }
    `)
    const reviews = latestReviewsScore.allStrapiGoogleReviewReviews.edges;
    const reviewsCount = latestReviewsScore.allStrapiGoogleReviewReviews.pageInfo.totalCount;
    const wordToNumber = {'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5};
    let sumOfScores = 0;

    reviews.map((review) => {
        const reviewStringValue = review.node.starRating;
        sumOfScores += wordToNumber[reviewStringValue];
        return(sumOfScores)
    })

    const averageRating = Math.round(sumOfScores / reviewsCount * 10) / 10;

    let cta_1_url = ''
    let cta_1 = ''
    let cta_2 = ''
    if(props.CTA_1_URL) {
        cta_1_url = GetURL(props.CTA_1_URL.id)

        var youtube_one  = cta_1_url.includes("youtube")
        var vimeo_one  = cta_1_url.includes("vimeo")
        if ((youtube_one == true) || (vimeo_one == true))
        { 
          cta_1 = 'video'
        }
    }
    let cta_2_url = ''
    if(props.CTA_2_URL) {
        cta_2_url = GetURL(props.CTA_2_URL.id)

        var youtube_two  = cta_2_url.includes("youtube")
        var vimeo_two  = cta_2_url.includes("vimeo")
        if ((youtube_two == true) || (vimeo_two == true))
        { 
          cta_2 = 'video'
        }
    }

    let image = getSrc(props.Banner_Image)
  
  return (
    <InView threshold={0}>
      {({ ref, inView }) => (
        <motion.div
          className="static-banner"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
            <div className="banner-item">
              <picture>
                <source media="(min-width:992px)" srcset={image} />
                <source media="(min-width:768px)" srcset={image} />
                <motion.img variants={imageVariants} className="banner-img" src={image} alt={`${props.page} ${props.Title ? props.Title.replace( /(<([^>]+)>)/ig, '') : ''}`}></motion.img>
              </picture>

              <div className="banner-content">
                <Container>
                  <Row>
                    <Col xs={12}>
                      <motion.div variants={titleVariants}>
                        <Breadcrumbs id={props.id} tag="service-landing-banner" />

                          <ReactMarkdown source={props.Title ? props.Title : ''} allowDangerousHtml />
                        <div className="btns-wrp d-md-flex align-items-center">
                          {props.CTA_1_Label ? 
                            <>
                              {cta_1 === 'video' ?
                              <Video class="btn d-block d-md-inline-block" label={props.CTA_1_Label} url={cta_1_url}/> : props.CTA_1_Email ?
                              <Link className="btn" href={`/${cta_1_url}${props.CTA_1_Email ? `?mail=${props.CTA_1_Email}` : ``}`}>{props.CTA_1_Label}</Link> :
                              <Link className="btn" to={`/${cta_1_url}`}>{props.CTA_1_Label}</Link>
                              }
                            </> : '' 
                          }

                          {props.layout === "Local_Love_Landing_Page" &&
                            <LocalLifeCategories />
                          }

                          {props.CTA_2_Label ?
                            <>
                              {cta_2 === 'video' ?
                              <Video title={props.Title ? props.Title : ''} class="btn d-block d-md-inline-block" label={props.CTA_2_Label} url={cta_2_url}/> : props.CTA_2_Email ?
                              <Link className="btn" href={`/${cta_2_url}${props.CTA_2_Email ? `?mail=${props.CTA_2_Email}` : ``}`}>{props.CTA_2_Label}</Link> :
                              <Link className="btn" to={`/${cta_2_url}`}>{props.CTA_2_Label}</Link>
                              }                        
                            </> : '' 
                          }

                        </div>
                        {props.Show_Review === true ?
                        <div className="google-reviews d-flex align-items-center">
                              <img loading="lazy" src={GoogleReview} alt="google-review" />
                              <p className="total-review">Rated <span>{averageRating}/5</span> by over {reviewsCount} Customers</p>
                        </div>
                        : '' }
                      </motion.div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
        </motion.div>
      )}
    </InView>
  );
}

export default StaticBanner
