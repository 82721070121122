import { Link } from "@StarberryUtils";
import React from "react";
import { Col, Row, Container, Breadcrumb } from 'react-bootstrap';
import HeaderTwo from "../../components/headerTwo/headerTwo"
import PropertyBreadcrumbs from '../../components/MakeProperty/MakePropertyBreadcrumb/PropertyBreadcrumbs'
import "../../scss/market-property.scss";
import ReactMarkdown from "react-markdown"
import Footer from "../footer/footer"
// import $ from "jquery";
import { getSrc } from "gatsby-plugin-image"

import { GetURL } from "../common/site/functions";

import ReactFullpage from '@fullpage/react-fullpage';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

// NOTE: if using fullpage extensions/plugins put them here and pass it as props
const pluginWrapper = () => {
    require('./fullpage.scrollOverflowReset.min');
};

const containerVariants = {
    hidden: {
      opacity: 0.7
    },
    visible: {
      opacity: 1,
      transition: {
        ease: [0.33, 1, 0.68, 1],
        duration: 0.4
      }
    }
  }

// const imageVariants = {
//     hidden: {
//         // opacity: 0.7,
//         // scale: 1
//     },
//     visible: {
//         // scale: 1.2,
//         // opacity: 1,
//         transition: {
//             ease: [0.33, 1, 0.68, 1],
//             duration: 0.8
//         }
//     }
// }

const titleVariants = {
    hidden: {
      opacity: 0,
      y: 40 
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.8,
        ease: [0.33, 1, 0.68, 1],
        duration: 1
      }
    }
  }

const SliderContent = (props) => {
    const { slider, state } = props
    const { destination, initialized } = state

    const isFirstLoad = !initialized || !destination || destination.index === 0

    return(
        <>
            {slider.map((slide, i) => {
                let url_1 = ''
                if(slide.CTA_1_Link) {
                    url_1 = GetURL(slide.CTA_1_Link.id)
                }
                let url_2 = ''
                if(slide.CTA_2_Link) {
                url_2 = GetURL(slide.CTA_2_Link.id)
                }

                return (
                    <React.Fragment key={i}>
                        <motion.div
                            className="section slider-block-col"
                            key={i}
                            data-tooltip={slide.Title}
                            initial="hidden"
                            animate={(isFirstLoad && i === 0) ? "visible" : (destination?.index === i) ? "visible" : "hidden"}
                            variants={containerVariants}
                        >
                            <img loading="lazy"
                                src={getSrc(slide.Background_Image)} alt={`${slide.Content.replace(/(<([^>]+)>)/ig, '')} - Martyn Gerrard`}
                                className="slider-block-img" />
                            <motion.div variants={titleVariants} className="slider-block-content">
                                <Container>
                                    <Row>
                                        <Col>
                                            <div className="slider-content">
                                                <div className="breadcrumb-block">
                                                    <PropertyBreadcrumbs />
                                                </div>
                                                {slide.Content ? <ReactMarkdown source={slide.Content} allowDangerousHtml /> : ''}
                                                <div className="slider-btn">
                                                    {slide.CTA_1_Label ? <Link className="btn" to={`/${url_1}`}>{slide.CTA_1_Label}</Link> : ''}
                                                    {slide.CTA_2_Label ? <Link className="btn btn-success ml-0 ml-md-4" to={`/${url_2}`}>{slide.CTA_2_Label}</Link> : ''}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </motion.div>
                        </motion.div>
                    </React.Fragment>
                )
            })}
            <Footer Select_Popular_Search="Static_Common" popularSearch="static" footerClass="section fp-auto-height" />
        </>
    )
}

const Fullpage = (props) => {
    return(
        <div className="content components--market-your-property--market-your-property">
            <section className="market-property-slider" id="market-property">
                <ReactFullpage
                    pluginWrapper={pluginWrapper}
                    //fullpage options
                    licenseKey={`${process.env.GATSBY_FULLPAGEJS}`}
                    scrollingSpeed={1000} /* Options here */
                    css3={true}
                    verticalCentered={true}
                    easingcss3="cubic-bezier(0.33, 1, 0.68, 1)"
                    navigation={true}
                    showActiveTooltip={true}
                    scrollOverflow={true}  /* Because we are using the extension */
                    scrollOverflowReset={true}
                    scrollOverflowResetKey={`${process.env.GATSBY_FULLPAGEJS_SCROLLOVERFLOW}`}
                    onLeave={() => {}}
                    render={({ state }) => {
                        return (
                            <ReactFullpage.Wrapper>
                                {props?.slider && <SliderContent {...props} state={state} />}
                            </ReactFullpage.Wrapper>
                        );
                    }}
                />
            </section>
        </div>
    )
}

export default Fullpage;

