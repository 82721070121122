// import { Link } from "@StarberryUtils";
import React from "react";
// import ReactMarkdown from "react-markdown"
import ServiceLinks from "../ServiceLinks/ServiceLinks"
// import Slider from 'react-slick';
import {Col, Row, Container } from 'react-bootstrap';
import  "./MgWork.scss";
import MgService01 from "../../../images/mg-service01.jpg";
// import MgService02 from "../../../images/mg-service02.jpg";
// import MgService03 from "../../../images/mg-service03.jpg";
//import "animate.css/animate.min.css";
// import Img from 'gatsby-image';
import { getSrc } from "gatsby-plugin-image"
import Loadable from '@loadable/component';
import LoadExternalScript from "../../utils/load-external-script";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext,Dot , DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import {
  isMobileOnly,
  isTablet,
  // browserName
} from "react-device-detect";
import { Helmet } from 'react-helmet';
import Tile from "./tile";
//import $ from "jquery"

import { inViewOptions } from '../../../components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";


const contentVariants = {
  hidden: {
      // opacity: 0
  },
  visible: {
      // opacity: 1,
      transition: {
          staggerChildren: 0.1
      }
  }
}

const contentItem = {
  hidden: { opacity: 0, y: 30 },
  visible: (custom) => ({
      opacity: 1,
      y: 0,
      transition: {
          delay: custom * 0.2,
          duration: 0.4,
          ease: [0.33, 1, 0.68, 1],
      }
  })
}

const MgWork =(props) => {  

  return(
    <>
    <InView {...inViewOptions} rootMargin="250px 0px">
      {({ ref, inView }) => (
        <motion.section
          className="work-services components--MgServives--MgWork--MgWork"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentVariants}
        >
    <div className="serviceupperdiv">
      
    <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={(isMobileOnly) ? 40 : (isTablet) ? 80 : 100}
        totalSlides={7}
        infinite={true}
        isPlaying={true}
        visibleSlides={(isMobileOnly) ? 1 : (isTablet) ? 2 : 3}
      >
        
          
        <Slider className="services-slider">
        {props.services.map((service, i) => {
          let image = service.Background_Image ? getSrc(service.Background_Image) : ''
          return (
            <Slide key={i} index={i} className="item">
              <motion.div
                variants={contentItem}
                custom={i + 1}
                className="service-item"
              >
                {service.Background_Image ? 
                  <img loading="lazy" src={image} alt={`${props.page} ${service.Label} - Martyn Gerrard`} />
                  :
                  <img loading="lazy" src={MgService01} alt={`${props.page} ${service.Label} - Martyn Gerrard`} />
                  }
                  <Tile url={service.Choose_Link} label={service.Label} content={service.Content} />
              </motion.div>
            </Slide>
          )
        })}
        
        </Slider>
        {/* <Dot /> */}
        <DotGroup 
          dotNumbers={false}
          disableActiveDots={false}
          showAsSelectedForCurrentSlideOnly={true}
        />
          
      </CarouselProvider>
          {props.Choose_Menu_to_Show ?
            <>
            
            <div className="mgservices-links">
            <Container>
              <Row>
                <Col lg={12}>
                    {props.Service_Links_Heading ? <h2 className="sec-title text-center">{props.Service_Links_Heading}</h2> : '' }
                </Col>
              </Row>
            </Container>
            </div>
            <ServiceLinks links={props.Choose_Menu_to_Show}/></> : '' 
          }

    </div>
        </motion.section>
      )}
    </InView>
    </>
)
}

export default MgWork
