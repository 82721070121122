// import { Link } from "@StarberryUtils";
// import PropTypes from "prop-types";
import React from "react";
import {Col, Row, Container } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
// import VideoCard from '../VideoCard/VideoCard';
import  "../MgFavourites/MgFavourites.scss";
// import favImg01 from "../../../images/fav-img01.jpg"
// import favImg02 from "../../../images/fav-img02.jpg"
// import favImg03 from "../../../images/fav-img03.jpg"
// import favImg04 from "../../../images/fav-img04.jpg"

import GenerateLink from "../../common/site/generate-link";


const InstructMGStyleTwo=(props)=>{

  return (
    <section className="mg-favourites reason-to-instruct-style-two">
      <Container>
        <Row>
          <Col md={12} className="head">
            {/* <ScrollAnimation animateIn='fadeInUp' animateOnce> */}
              <ReactMarkdown source={props.Content ? props.Content : ''} allowDangerousHtml className="top-head"/>
            {/* </ScrollAnimation> */}
          </Col>
        </Row> 
        <Row>

        {/* <ScrollAnimation animateIn='fadeInUp' animateOnce > */}
          {props.Reasons.map((reason, i) => {

            return (
              <Col xl={6} className="instruct-grid">
              
                  <div className="favourite-item d-flex">
                      {reason.Image ? <figure>
                          <img loading="lazy" src={reason.Image.childImageSharp.gatsbyImageData.images.fallback.src} alt={`${reason.Title} - Martyn Gerrard`} />
                      </figure> : '' }
                      <div className="info">
                          {reason.Link ? <GenerateLink link={reason.Link} class="info-title" label={reason.Title} >{reason.Title}</GenerateLink> : <p className="info-title">{reason.Title}</p>}
                          <ReactMarkdown source={reason.Content ? reason.Content : ''} allowDangerousHtml />
                      </div>
                  </div>
              </Col>
            );
          })}
          {/* </ScrollAnimation> */}

        </Row>       
      </Container>
    </section>
  );
  }

export default InstructMGStyleTwo
