import { Link } from "@StarberryUtils";
import PropTypes from "prop-types";
import React from "react";
import {Col, Row, Container } from 'react-bootstrap';
import  "../Intro/Intro.scss";
import ReactMarkdown from "react-markdown"
import Breadcrumbs from "../../MgServices/Breadcrumb/Breadcrumb"

import { GetURL } from "../../common/site/functions";

const StaticContent = (
  props,
  // { siteTitle }
) => {


  let url = '/market-your-property'
  if(props.Right_Side_Block_CTA_Link) {
    url = GetURL(props.Right_Side_Block_CTA_Link.id)
  }
  
return(
  <section className="managing-sec managing-intro static-news-page static-content-two">
    <div className="rental-market">
      {props.breadcrumb !== "no" &&
      <Breadcrumbs id={props.id}/> }
      <Container>
        <Row>
          <Col className="order-2 order-xl-1" xl={8}>
            <div className="rental-post news-content">
              {props.Content ?
                <ReactMarkdown source={props.Content} allowDangerousHtml className="editor"/> : ''
              }
            </div>
          </Col>
          <Col className="order-1 order-xl-2" xl={4}>
            <div className="get-quote d-none d-xl-block">
              <h3 className="get-title">{props.Right_Side_Block_Title ? props.Right_Side_Block_Title : 'Interested in Selling or Letting your property?'}</h3>
              <Link to={`/${url}`} class="btn">{props.Right_Side_Block_CTA_Label ? props.Right_Side_Block_CTA_Label : 'market your property'}</Link>
              
              {props.Speak_with ?
                <>
                <p className="get-desc">We’re always here to help. Hit the button above or get in touch with {props.Speak_with.Name}.</p>
              
              <div className="get-profile d-flex align-items-center">
                {props.Speak_with.Tile_Image ? <figure><img loading="lazy" src={`${props.Speak_with.Tile_Image.url ? props.Speak_with.Tile_Image.url : props.Speak_with.Tile_Image.publicURL}`} alt="profile-img" /></figure> : '' }
                <div className="prof-info">
                  <span className="name">{props.Speak_with.Name}</span>
                  <a href={`tel:${props.Speak_with.Phone}`}>{props.Speak_with.Phone}</a>
                </div>
              </div></> : '' }

            </div>
          </Col>
        </Row>
      </Container>
    </div>
  </section>
)
}

StaticContent.propTypes = {
  siteTitle: PropTypes.string,
}

StaticContent.defaultProps = {
  siteTitle: ``,
}

export default StaticContent
