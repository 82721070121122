import React, { useState } from "react"
import { Form } from "react-bootstrap"
import { Row, Col, Container } from "react-bootstrap"

var d = new Date()
var n = d.getHours() - 4
// console.log("time",n)

function myplaceholder(props) {
  return <div>{props.html}</div>
}

function createMarkup(props) {
  return { __html: props.html }
}

function MyComponent(props) {
  return <div dangerouslySetInnerHTML={createMarkup(props)} />
}
const RadioboxField = ({
  fieldClass,
  name,
  value,
  required,
  placeholder,
  handlechange,
  checked,
  lastchild,
  step,
  type,
  grpmd,
}) => {
  function isSmallerThanLondonTime(selectedTime) {
    let d = new Date()
    let utc = d.getTime() + d.getTimezoneOffset() * 60000
    let nd = new Date(utc + 3600000 * "+1")
    let londonTime = nd.toLocaleTimeString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    })

    return londonTime > selectedTime
  }

  const times = [
    "08:00",
    "10:00",
    "12:00",
    "14:00",
    "16:00",
    "18:00",
    "20:00",
    "21:00",
  ]

  return (
    <>
      {type == "time_radio" ? (
        <Form.Group
          as={Col}
          md={grpmd}
          className={`${type} ${step}`}
          controlId={"validation" + name}
        >
          <div class="radio-toolbar">
              {times.map((time, i) => {
                return (
                  <div key={"radiocol" + i} className="radio-item">
                    <input
                      key={"radio" + i}
                      type="radio"
                      id={`radio${i + 1}`}
                      name="val_time"
                      value={time}
                      disabled={isSmallerThanLondonTime(time)}
                      checked={!isSmallerThanLondonTime(time)}
                    />
                    <label for={`radio${i + 1}`}>{time}</label>
                  </div>
                )
              })}
          </div>
        </Form.Group>
      ) : (
        <div className={`form-group radio-wrap ${lastchild} ${step}`}>
          <label className="radio-label">
            <input
              type="checkbox"
              id={name}
              name={name}
              value={value}
              checked={checked}
              onChange={handlechange}
            />
            <span className="radio-ico"></span>
            {value}
          </label>
        </div>
      )}
    </>
  )
}
export default RadioboxField
