import React from "react";
import "../Banner/banner.scss"
import VideoPlay from "../../Play/custom-video";
import { getSrc } from "gatsby-plugin-image"


const PlainBanner = (props) => {


  return (
      <div className="home-banner news-card components--Home--Banner--plain-banner header-full-banner">
        <figure className="news-img">
          <img loading="lazy" src={getSrc(props.image)} alt="news-img" />
          {props.Embed_Video_URL ? <VideoPlay url={props.Embed_Video_URL}/> : '' }
        </figure>
      </div>
    )
}

export default PlainBanner
