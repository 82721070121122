// import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react"
import {Row, Col, Container } from 'react-bootstrap';
// import ModalTiny from "react-bootstrap/Modal"
import logoBlack from "../../images/mg-logo-black.svg";
// import NewsBreadcrumbs from "./NewsBreadcrumbs/NewsBreadcrumbs"
import Breadcrumbs from "../MgServices/Breadcrumb/news-breadcrumb"
import PersonInfo from "./PersonInfo/PersonInfo"
import CustomerReviews from "./CustomerReviews/CustomerReviews"
import LargeVideo from "./largeVideo/largeVideo"
import "./PersonDetail.scss"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import SEO from "../seo"

import { inViewOptions } from '../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        ease: [0.33, 1, 0.68, 1],
        duration: 0.4
      }
    }
}
const containerText = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.6
    }
  }
}
const containerImage = {
  hidden: { opacity: 0, y: 50, scale: 0.7 },
  visible: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.8
    }
  }
}

const PersonDetail =(props) => {


const SINGLE_PERSON =  gql`
  query GetSinglePerson($URL: String!){ 
  people(where:{URL: $URL}){
        Name
        Designation
        Phone
        Address
        Email
        About
        Meta_Title
        Meta_Description
        Membership
        Full_Image {
          url
        }
        Embed_Video_URL
        Upload_Video {
          url
        }
        Customer_Reviews {
          Name
          Image {
            url
          }
          Upload_Video {
            url
          }
          Embed_Video_URL
        }
        Calendly_Link
        VideoAsk_Link
        imagetransforms
        id
     }
  }
`;

  const { loading, error, data } = useQuery(SINGLE_PERSON, {
    variables: { URL: props.slug } });

  if (loading) return (
    
    <section className={"loader-wrapper"}>
    <div id="loader-wrapper">
      <div id="loader" class="new-loader">
        <div className="new-loader-anime"></div>
        <img loading="lazy" className="logo-white" src={logoBlack} className="loader-logo" alt="logo"/>
      </div>
      <div class="loader-section section-left"></div>
      <div class="loader-section section-right"></div>
    </div>
  </section>);

    return(
    <>
    {data.people.map((data, index) => {  
    let video_url = ''
    if(data.Embed_Video_URL != null) {
      video_url = data.Embed_Video_URL
    }
    else if(data.Upload_Video != null) {
      video_url = data.Upload_Video.url
    }


    const image_url = data.Full_Image.url; 

    let processedImages = JSON.stringify({});
    if (data?.imagetransforms?.Full_Image_Transforms) {
        processedImages = data.imagetransforms.Full_Image_Transforms;
    }

    let metadesc = "Meet "+data.Name+" here. Martyn Gerrard an leading estate agents in London contact us for more information on all your property needs."
        return (
          <InView {...inViewOptions}>
			      {({ ref, inView }) => (
              <motion.div
                className="content person-detail"
                ref={ref}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={container}
              >
                <SEO title={data.Meta_Title ? data.Meta_Title : data.Name} description={metadesc} />
                <motion.div variants={containerImage}>
                  <div className="big-video-wrap"></div>
                  <div className="mobile-person-detail-img">
                    <LargeVideo Full_Image={image_url ? image_url : ''} video_url={video_url} videoask={data.VideoAsk_Link} name={data.Name} id={data.id} processedImages={processedImages ? processedImages : ''}/>
                  </div>
                </motion.div>
                <motion.div
                  className="person-info-section"
                  variants={containerText}
                >
                  <Container>
                    <Row> 
                      <Col xl={12}>
                        <Breadcrumbs url={props.alias} tag="person" Title={data.Name} class="person-detail-breadcrumb"/>
                      </Col>
                    </Row> 
                  </Container>
                  <Container>
                    <Row> 
                      <Col xl={7} md={7}>
                        <PersonInfo name={data.Name} Designation={data.Designation} membership={data.Membership} Phone={data.Phone} Address={data.Address} url={data.Calendly_Link} About={data.About} Email={data.Email}/>

                        {data.Customer_Reviews.length != 0 ?
                        <div className="people-details-review-block">
                          <CustomerReviews Customer_Reviews={data.Customer_Reviews}/>
                        </div> : '' }
                      </Col>
                      <Col xl={5} md={5} className="desktop-person-detail-img">
                        <LargeVideo Full_Image={image_url ? image_url : ''} video_url={video_url} name={data.Name} id={data.id} processedImages={processedImages ? processedImages : ''}/>
                      </Col>
                    </Row>
                  </Container>
                </motion.div>
              </motion.div>
            )}
          </InView>
        )
        })}
      </>
    )
}

export default PersonDetail;

