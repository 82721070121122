import React,{useState, useEffect} from 'react'
import { Link } from "@StarberryUtils";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import logoBlack from "../../images/mg-logo-black.svg";

import NewsHead from "./NewsHead/NewsHead"
import NewsPost from "./NewsPost/NewsPost"
import Breadcrumbs from "../MgServices/Breadcrumb/Breadcrumb"
import ServiceHead from "../MgServices/ServiceHead/ServiceHead"
import FilterNews from "./filter-news-category"
import SEO from "../seo"

const GET_ALL_NEWS = gql`
  query GetNews($url: String!){
	  propertyNews(sort: "Date:desc",where:{Category: {URL:$url}})  {
	    Title
	    URL
	    Tile_Image {
	      url
	    }
	    Date
	    Category {
	      Name
	      URL
	    }
	    id
	    imagetransforms
      }

	  newsCategories(sort: "Sort:desc",where:{URL:$url}) {
	    Name
	    URL
	    Meta_Title
	    Meta_Description
	    Heading
	  }
  }
`;


function FilterCategoryNews(props) {

  const [ search_text, setSearchText ] = useState('');

  const handlechange = (event) => {
    // remove initial empty spaces
    var search_val = event.target.value.trimStart();
    setSearchText(search_val);
  }


	const { loading, error, data } = useQuery(GET_ALL_NEWS, {variables: { url: props.slug }});

  	if (loading) return (

		<section className={"loader-wrapper"}>
		<div id="loader-wrapper">
		  <div id="loader" class="new-loader">
			<div className="new-loader-anime"></div>
			<img loading="lazy" className="logo-white" src={logoBlack} className="loader-logo" alt="logo"/>
		  </div>
		  <div class="loader-section section-left"></div>
		  <div class="loader-section section-right"></div>
		</div>
	  </section>
	  );

  	return (
		<div className="content news-page">
			<SEO title={data?.newsCategories ? data?.newsCategories[0]?.Meta_Title : ''} description={data?.newsCategories ? data?.newsCategories[0]?.Meta_Description : ''} />
			<Breadcrumbs id="5ff438b9a11e7323e150d184" page={data.newsCategories[0].Name}/>
			<ServiceHead Title={data.newsCategories ? data.newsCategories[0].Heading : "Stay up to date with our latest news, events and insights."} />
			<NewsHead news_data={data.propertyNews} tag={data.newsCategories[0].Name} handlechange={handlechange}/>
			{search_text != '' ? <FilterNews text={search_text} category={props.slug}/> :
			<div className="video-main-landing">
			<NewsPost data={data.propertyNews} tag=""/>
			</div>
			}
	    </div>
  	)
}



export default FilterCategoryNews
