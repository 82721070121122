import { Link } from "@StarberryUtils";
import PropTypes from "prop-types";
//import ScrollAnimation from 'react-animate-on-scroll';
import React,{useEffect, useState} from "react";
import Slider from 'react-slick';
import {Col, Row, Container } from 'react-bootstrap';
import  "../CaseStudies/CaseStudies.scss";
import studyImg1 from "../../../images/study-image01.jpg"
import studyImg2 from "../../../images/study-image02.jpg"
import studyImg3 from "../../../images/study-image03.jpg";
import studyBanner from "../../../images/study-banner-img.jpg";
import studyBanner376 from "../../../images/study-banner376.jpg";
import studyBanner768 from "../../../images/study-banner768.jpg";
import studyBanner992 from "../../../images/study-banner992.jpg";
import playButton from "../../../images/play-button.svg"
import pattern from "../../../images/pattern.png"
import VideoPlay from "../../Play/VideoPlay";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import { NewsDate } from "../../common/site/functions";

  const RelatesNews=(props)=>{


    const RELATED_NEWS =  gql`
query GetNews{   
  propertyNews(sort:"createdAt:desc") {
    Title
    Tile_Image {
      url
    }
    URL
    Date
    Category {
      URL
    }
  }
}
`;
      const [isPlay,setPlay] = useState(false);
      const [isPlaying,setPlaying] = useState(false);

  const settings =  {
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: true,
    arrows: false,
    
    autoplay: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  // document.documentElement.style.setProperty('--animate-duration', '2s');

const { loading:relatednewsloading, error:relatednewserror, data:relatednews } = useQuery(RELATED_NEWS);

  if (relatednewsloading) return <p>Loading ...</p>;

  const relatedValue = relatednews?.propertyNews?.slice(0,10);
  const insightsNews = relatedValue.filter(val => val.Title != props.Title)
  
  return(
  <section className="case-studies related-news components-Home-CaseStudies-related-news">
    <Container>
      <Row> 
        <Col md={12}>
            <h2 className="sec-title text-center text-md-left">Other insights that may interest you...</h2>
            <div className="study-head d-md-flex justify-content-between align-items-center">
              <p className="txt text-center text-md-left">See what else is happening in the property market, both nationally and in your area.</p>
              <Link className="btn btn-success d-none d-md-inline-block" to="../../">view more</Link>
            </div>
        </Col>
      </Row>
      <Row> 
        <Col md={12}>
        <Slider className="study-slider"  {...settings} >

        {insightsNews.map((news, i) => {
          return (
                <Link to={`../../${news.Category[0].URL}/${news.URL}`}><div className="started-details">
                  <figure className="study-img"><img loading="lazy" src={`${news.Tile_Image.url}`} alt={`${news.Title} - Martyn Gerrard`} /></figure>
                  <p className="schedule">{NewsDate(news.Date)}</p>
                  <strong className="study-title d-block">{news.Title}</strong>
                </div></Link>
          )
        })}



          </Slider>
        </Col>
      </Row>
    </Container>
    
    <VideoPlay
              isOpen = { isPlay } 
              isCloseFunction = { setPlay }
              videoId="luZRKtAUb2A"
              isAutoPlay={1}
            />
    <VideoPlay
              isOpen = { isPlaying } 
              isCloseFunction = { setPlaying }
              videoId="9G-1K4fTB7s"
              isAutoPlay={1}
            />
  </section>
)
  }

export default RelatesNews