import React from "react"
import HTMLParser from "html-react-parser"
import "./VipContainer.scss"
import Breadcrumbs from "../MgServices/Breadcrumb/Breadcrumb"
import CTA from "../Home/Banner/banner-cta"
import { Container } from "react-bootstrap"
import VIPLogo from "../../images/mg_vip_logo.png"
import { GetURL } from "../common/site/functions"
import Video from "../Play/custom-video";
import { Link } from "@StarberryUtils"
// import "./Breadcrumb.scss"

const VipContainer = props => {
  let urlTwo = GetURL(props.Module?.Cta_Two_Link.id)
  return (
    <div
      className={`vip_wrapper ${
        props.Module.Quotes == "Lettings"
          ? "vip_letting_container"
          : "vip_sales_container"
      }`}
    >
      <Container className="vip_container">
        <div className="vip_content_left">
          <Breadcrumbs id={props.id} tag="contact-landing" />
          <div>{HTMLParser(props.Module.Heading_Block)}</div>
          <div>{HTMLParser(props.Module.Description_Block)}</div>
          <div className="btns_section">
            <div
              className={`valuation-btn ${
                props.Module.Cta_One != "Book a valuation" ? "text-haigh" : ""
              }`}
            >
              <CTA
                label={props.Module.Cta_One}
                url={props.Module.Cta_One_Link}
              />
            </div>
            <div className="qualify-btn">
              {/* <CTA
                label={props.Module.Cta_Two}
                url={props.Module.Cta_Two_Link}
              /> */}
              <Link className="btn btn-success" to={`/${urlTwo}`}>
                {props.Module.Cta_Two}
              </Link>
            </div>
          </div>
        </div>
        <div className="vip_content_right">
          {props.Module.Quotes != "Lettings" && (
            <h2 className="quotes_type"> <em>{props.Module.Quotes}</em></h2>
          )}

          <p className="become_text_type">Become a</p>

          {props.Module.Quotes == "Lettings" && (
            <div className="become_type">
              <p>{props.Module.Quotes.toUpperCase()}</p>
            </div>
          )}
          <div className="mg_vip_image_container">
            <div>
              <img src={VIPLogo} alt="VIP_Logo" />
            </div>
          </div>
          <div>
            {
              props.Module.Quotes !== "Lettings" && 
              <div className="vip_video_container">
                <div className="vip-video">
                  <img loading="lazy" src={props.Module.Tile_Image_vip?.childImageSharp?.gatsbyImageData?.images?.fallback?.src} alt="mg_vip" />
                  <Video title="vip_video" url={props.Module.Video_Url_vip}/>
                </div>
              </div>  
            }
          
          </div>

        </div>
      </Container>
    </div>
  )
}

export default VipContainer
