import React from 'react'
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import {Row, Col, Container } from 'react-bootstrap';

import NewsPost from "./NewsPost/NewsPost"


const SEARCH_NEWS = gql`
  query SearchNews($search_text: String!){	
	  propertyNews(sort:"Date:desc",where:{Title_contains:$search_text})  {
	    Title
	    URL
	    Tile_Image {
	      url
	    }
	    Date
	    Category {
	      Name
	      URL
	    }
	    id
	    imagetransforms
      }
  }
`;


function SearchNews(props) {

    const { loading, error, data } = useQuery(SEARCH_NEWS, { variables: { search_text:props.text } });

  	if (loading) return <p>Loading ...</p>;
	return (
	<div className="components-News--filter-news">
	{data.propertyNews == "" ? 
  		<div className="news-posts" >
    		<Container>
        		<Row> 
        			<Col lg={12}>
                		<div className="no-match-news">
                			No match found
                		</div>
        			</Col>
        		</Row>
        	</Container>
        </div> : <NewsPost data={data.propertyNews} tag="parent"/>}
	</div>
	)
}

export default SearchNews
