// import { Link } from "@StarberryUtils";
import React from "react";
import {Container } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import logoBlack from "../../images/mg-logo-black.svg";
import CanHelpStyleTwo from "../Home/CanHelp/CanHelpStyleTwo"
import SEO from "../seo"

import Content from "../Static/static-page/job-full-details"

const JobDetail =(props) => {


const SINGLE_JOB =  gql`
  query GetSingleJob($URL: String!){ 
  careers(where:{URL: $URL}){
        Title
	    Location
	    Salary
	    Job_Description
	    Requirements
	    Meta_Title
	    Meta_Description
	    Apply_for_this_Job_Content
	    Attach_File {
	      url
	    }
	    Speak_with {
	      Name
		  URL
	      Phone
	      Tile_Image {
	        url
	      }
	    }
	    Footer_Quick_Links_Content
	    Footer_Quick_Link {
	      Label
	      URL {
	        id
	      }
	    }
     }
  }
`;

  const { loading, error, data } = useQuery(SINGLE_JOB, {
    variables: { URL: props.slug } });

  if (loading) return (
	<section className={"loader-wrapper"}>
<div id="loader-wrapper">
  <div id="loader" class="new-loader">
	<div className="new-loader-anime"></div>
	<img loading="lazy" className="logo-white" src={logoBlack} className="loader-logo" alt="logo"/>
  </div>
  <div class="loader-section section-left"></div>
  <div class="loader-section section-right"></div>
</div>
</section>);

	return (
		<div className="content job-details-page">
    	{data.careers.map((data, index) => {  
    		return (
    		<>
    		    <SEO title={data.Meta_Title ? data.Meta_Title : data.Title} description={data.Meta_Description ? data.Meta_Description : data.Title} />
    			<Content Speak_with={data.Speak_with} alias={props.alias} slug={props.slug} Title={data.Title} Job_Description={data.Job_Description} Requirements={data.Requirements} Location={data.Location} Salary={data.Salary} Apply_for_this_Job_Content={data.Apply_for_this_Job_Content} Attach_File={data.Attach_File}/>

    			<CanHelpStyleTwo links={data.Footer_Quick_Link} title={data.Footer_Quick_Links_Content} tag="markdown"/>
    		</>
    		)
    	})}
		</div>
	)
}

export default JobDetail;