import React from 'react'
import {Form, Button } from 'react-bootstrap';

const SearchFormPeople = ({handlechange}) => (
    <Form className="search-field align-items-center" onSubmit={e => { e.preventDefault(); }}>
	  <Form.Group>
	    <Form.Control type="text" autoFocus id="search" placeholder="Search people" onChange={handlechange}/>
	  </Form.Group>
	</Form>
);

export default SearchFormPeople;
