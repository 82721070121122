import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react"
import "./BlockHeading.scss"
import listImg from "../../../images/list.svg"
import markerImg from "../../../images/marker.svg"
import ReactMarkdown from "react-markdown"

const BlockHeading = (props) => {

  const makeMapAvailable = () => {
    try{
      let mapElement = document.querySelector('.ouroffice-page');
      let elementList = [...mapElement.classList];
      if(!elementList.indexOf("map-show") >= 0) {
       mapElement.classList.add("map-show")
      }
    } catch(e){
      
    }
  } 
  const makeListAvailable = () => {
    try{
      let mapElement = document.querySelector('.ouroffice-page');
      let elementList = [...mapElement.classList];
      if(elementList.indexOf("map-show") >= 0) {
        mapElement.classList.remove("map-show")
      }
    } catch(e){
      
    }
  } 

  return (
        <div className="office-block d-flex align-items-start">
          <div className="office-block-title">
            <ReactMarkdown source={props.content ? props.content : ''} allowDangerousHtml />
           </div>
            
           <div className="map-bnt-wrap">
           <Link to="#"  onClick={e=>makeListAvailable()}><i className="icon-list"></i></Link>
                          <Link to="#" className="ml-3" onClick={e=>makeMapAvailable()}><i className="icon-map-active"></i></Link>
          
           </div>

           </div>
           
  
  )
}

export default BlockHeading
