
import React,{useEffect, useState} from "react";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";

import "./NewsCard.scss"
  const NewsCard=(props)=>{



  return(

              <div className="news-card components-News-NewsCard-NewsCard">
                <figure className={`news-img ${props.className}`}>

                    <ImageTransform imagesources={props.newsImg} renderer="srcSet" imagename={props.img} attr={{ alt: props.newsTitle + ' - Martyn Gerrard', class:'' }} imagetransformresult={props.processedImages} id={props.id}/>

                  </figure>
                <p className="schedule">{props.schedule}</p>
                <strong className="news-title d-block">{props.newsTitle}</strong>
              </div>
)
  }

export default NewsCard
