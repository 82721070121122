import React, { useRef, useState, useEffect } from "react"

import { Form, Button } from "react-bootstrap"
import { getUser } from "@myaccountServices/store/utils"

import InputField from './elements/input'
import SelectField from './elements/select'
import FileField from './elements/file'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
//import bsCustomFileInput from 'bs-custom-file-input'
import $ from 'jquery'
import _ from "lodash";

import "../../scss/forms.scss";

import axios from "axios"
import * as qs from "query-string"
import { Link } from "gatsby"

function RecommForm(props) {

  const userData = getUser();
  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");
    const [file, setFile] = useState(""); // storing the uploaded file
// storing the recived file from backend
    const [data, getFile] = useState({ name: "", path: "" });
    const [progress, setProgess] = useState(0); // progess bar
    const el = useRef(); // accesing input element

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  var all_cat = [];
    for(var i=0; i < props.AllAreas.length; i++){
      all_cat.push(props.AllAreas[i].node.Title)
    }

  var all_recomm = [];
    for(var i=0; i < props.Alllocallife.length; i++){
      all_recomm.push(props.Alllocallife[i].node.Name)
    }


  const fields = ([
      {
        element: "config",
        formname: "Local Recommendation",
        error_text: "Highlighted fields are required",
        success_text: "Thank you for your interest. A member of our team will contact you shortly.",
        email_temp_user: "coupon_code_user",
        email_temp_admin: "coupon_code_admin",
        email_server_func: "couponcode",
        event_tracking: "recommendation",
        page_url: "/about/loving-life/"
      },
      {
        grpmd: "12",
        label: "Name",
        placeholder: "Name *",
        name: "name",
        type: "text",
        element: "input",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        fieldClass: "",
        labelClass: "content_b-18",
        defaultValue: userData?.name || ``,
      },
      {
        grpmd: "12",
        label: "Email Address",
        placeholder: "Email Address *",
        name: "email",
        type: "email",
        element: "input",
        required: true,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        class: "",
        defaultValue: userData?.email || ``
      },
      {
        grpmd: "12",
        label: "Telephone Number:",
        placeholder: "Telephone Number *",
        name: "telephone",
        type: "text",
        element: "input",
        required: true,
        patternchk:"^[+]*[0-9-()]+(\\s+[0-9-()]+)*$",
        fieldClass: "",
        labelClass: "content_b-18",
        defaultValue: userData?.mobile || ``
      },
      {
        type: "hidden",
        element: "input",
        name:"cvname"
      },
      {
        grpmd: "12",
        label: "Message",
        placeholder: "Message",
        name: "message",
        required: true,
        element: "textarea",
        class: "mb-4",
        rows:"3",
        labelClass: "content_b-18"
      },
      {
        name: "submit details",
        type:"submit",
        element: "button",
        value: "submit",
        class: "btn",
        formclass: ""
      },
      {
        text: '<p>By clicking Submit, you agree to our <a href="/terms-and-conditions/"> Terms &amp; Conditions </a> and <a href="/privacy-policy/"> Privacy Policy </a></p>',
        element: "html",
        class: "terms"
      },
      {
        element: "captcha",
        class: "py-2 captcha-class",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  const handleAttachment = event => {
      const fileItem = event.target.files[0]; // accesing file

      var d = document.getElementById('custom-file');
      //alert(d.value);
      $('.form-control-browsefile .custom-file-label').html(d.value.replace(/C:\\fakepath\\/, ''));


      setFile(fileItem); // storing file

  };


  useEffect(() => {

    //bsCustomFileInput.init()

    if (token !== '') {

 const processFromData = async () => {

      formvalues['g-recaptcha-response'] = token;

      let formData = new FormData();
      if (formvalues?.files) {
        formData.append('files.attachment', formvalues.files, formvalues.files.name); // appending file
      }

      formData.append('data', JSON.stringify(formvalues));

      postFormData(formData).then(apiRes => {

          if (apiRes?.attachment?.url) {
            // If image from externall url, use that
            if (apiRes.attachment.url.match(/(http|https):\/\//g)) {
              formvalues['file'] = `${apiRes.attachment.url}`;
              formvalues['cvname'] = `${apiRes.attachment.url}`;
            } else { // or we consider it is loading from api url
              formvalues['file'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment.url}`;
              formvalues['cvname'] = `${process.env.GATSBY_STRAPI_FORM_URL}${apiRes.attachment.url}`;
            }
          }

      // lets send mail
      const axiosOptions_email = {
        url: '/.netlify/functions/server/'+fields[0].email_server_func,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(formvalues),
      }

      axios(axiosOptions_email)
        .then(response => {
          console.log('mail sent!')
        })
        .catch(err =>
          console.log(err)
        );
      });

      const url = typeof window !== 'undefined' ? window.location.href : ''
      if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
        fields[0].formname = 'Get mortgage help';
      }
      // tracking event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': 'form-'+fields[0].event_tracking,
        'formId': 'form-'+fields[0].event_tracking,
        'formName': fields[0].formname,
        'formLabel': fields[0].formname
      });

      setShowerror(false);
      setThankyou(true);
      props?.setHeadingToggle(true);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {

      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      var businessName = sessionStorage.getItem('BusinessName');

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g-recaptcha-response'] = token;
      json['business_name'] = businessName;
      json['extra'] = _.omit(json, ["formname", "name", "email", "telephone"])


      json['files'] = file;
      setFile(file);
      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      $('.recommendation-form').hide();
      $('.form-extra').show();
      document.getElementById("form-extra-title").innerHTML = "Thank you for your interest. A member of our team will contact you shortly.";

      // reset form
      const form = event.target
      form.reset();

    }
  };

  return (
    <>
    <div class="form-extra"><h1 id="form-extra-title"></h1>
      {showthankyou && <Link to={`/about/loving-life/`} className="btn">Back to Local Loves</Link>}
    </div>
      <div className="career-form form stbform valuation-main recommendation-form">
    <div ref={myRef} />

    {showerror && <div className="alert-error">
      <p>{fields[0].error_text}</p>
    </div>}

    {showthankyou && <div className="alert-success">
      <p>{fields[0].success_text}</p>
    </div>}


      <Form className="form" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit} >
      <div className="step-block">
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="bot-field" />
        {fields.map((field, index) => {
            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                  defaultValue={field.defaultValue}
                />
              );
            }
            if ( "file" === field.element ) {
              return (
                <FileField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  type={field.type}
                  fieldClass={field.fieldClass}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  key={`${field.element}~${index}`}
                  accept={field.accept}
                  handlechange={handleAttachment}
                  required={field.required}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("html" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  formclass={field.formclass}
                  type={field.type}
                  value={field.value}
                  key={`${field.element}~${index}`}
                />
              );
            }
          })
        }
            </div>
      </Form>
    </div>
    </>
  );
}


const RecommFormPage = (props) => (
  <RecommForm  Alllocallife={props.Alllocallife} AllAreas={props.AllAreas} setHeadingToggle={props.setHeadingToggle}/>
)

export default RecommFormPage
