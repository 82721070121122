import React from "react"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import Static from "../components/Static/static-page/static-page"
import RelatedNews from "../components/Home/CaseStudies/related-news"
import HeaderTwo from "../components/headerTwo/headerTwo"
import Footer from "../components/footer/footer"
import { Container } from 'react-bootstrap';
import logoBlack from "../images/mg-logo-black.svg";
import CanHelp from "../components/Home/CanHelp/CanHelp"
import Subscribe from "../components/News/Subscribe/Subscribe"
import SEO from "../components/seo"

const SINGLE_NEWS =  gql`
  query GetSingleNews($URL: String!){	
	propertyNews(where:{URL: $URL}){
	    Title
	    Date
	    Meta_Title
	    Meta_Description
	    Content {
	      ...on ComponentModulesNewsContent {
	        id
	        Content
	      }
	      ...on ComponentModulesVideo {
	        Upload_Video {
	          url
	        }
	        Embed_Video_URL
	        Background_Image {
	          url
	        }
	      }
	    }
	    Category {
	      Name
	      URL
	    }
	    Speak_with {
	      Name
	      Tile_Image {
	        url
	      }
	      Phone
	    }
	    Right_Side_Block_Title
	    Right_Side_Block_CTA_Label
	    Right_Side_Block_CTA_Link {
	      id
	      Alias
	    }
	    Author
	    Upload_File {
	      url
	    }
     }
  }
`;

function NewsDetail(props) {
	const { loading, error, data } = useQuery(SINGLE_NEWS, {
    variables: { URL: props.detailpage }
  });
	var page_url = props?.alias?.split( '/' )

	if (loading) return (
        <section className={"loader-wrapper"}>
    <div id="loader-wrapper">
      <div id="loader" class="new-loader">
        <div className="new-loader-anime"></div>
        <img loading="lazy" className="logo-white" src={logoBlack} className="loader-logo" alt="logo"/>
      </div>
      <div class="loader-section section-left"></div>
      <div class="loader-section section-right"></div>
    </div>
    <SEO title={props?.pageContext?.Meta_Title ? props?.pageContext?.Meta_Title : props?.pageContext?.Title} description={props?.pageContext?.Meta_Description ? props?.pageContext?.Meta_Description : props?.pageContext?.Title} />
  </section>);

    return (
    <>
    <SEO location={props.location} title={props?.pageContext?.Meta_Title ? props?.pageContext?.Meta_Title : props?.pageContext?.Title} description={props?.pageContext?.Meta_Description ? props?.pageContext?.Meta_Description : props?.pageContext?.Title} />
        <HeaderTwo />

        <div className="content news-details-full-page">
    	{data.propertyNews.map((data, index) => {  
            return <>
        		<SEO title={data.Meta_Title ? data.Meta_Title : data.Title} description={data.Meta_Description ? data.Meta_Description : `Read about ${data.Title} here, sign up with us today to stay updated about the latest property news.`} />
    			<Static Title={data.Title} Date={data.Date} Author={data.Author} Content={data.Content} Category={data.Category} Speak_with={data.Speak_with} url={page_url.pop()} category={props.category} tag="details" Right_Side_Block_Title={data.Right_Side_Block_Title} Right_Side_Block_CTA_Label={data.Right_Side_Block_CTA_Label} Right_Side_Block_CTA_Link={data.Right_Side_Block_CTA_Link} Upload_File={data.Upload_File} />
            	<Container>
    				<Subscribe />
            	</Container>
    			<RelatedNews Title={data.Title}/>
            </>
        })}
        </div>
        <CanHelp />
		<Footer Select_Popular_Search="Static_Insights" popularSearch="static"/>
    </>
    )
}

export default NewsDetail
