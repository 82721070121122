import { Link } from "@StarberryUtils";
// import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import {Col, Row, Container } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
// import HelpCard from "./HelpCard/HelpCard"
// import BgShadowWhite from "../../../images/bg-shadow-white2.svg";
import  "../CanHelp/CanHelp.scss";
import { GetURL } from "../../common/site/functions";

import { LOCAL_RECOMMENDATION_PAGE_URL } from "../../common/site/constants";

import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.3
    }
  }
}

const RecommendationCanHelp=(props)=> {
    let url = ''
    if(props.Sticky_CTA_1_Link) {
        url = GetURL(props.Sticky_CTA_1_Link.id)
    }
    let url_1 = ''
    if(props.Sticky_CTA_2_Link) {
        url_1 = GetURL(props.Sticky_CTA_2_Link.id)
    }

    return(
      <InView {...inViewOptions}>
        {({ ref, inView }) => (
          <motion.section
            className="help-sec components--Home--CanHelp--CanHelp help-sec-style-four"
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <Container>
                {props.Alignment == "Default" ?
              <Row className="recommendation one-cta-only"> 
                <Col md={12} lg={8} className="col1">
                    <ReactMarkdown source={props.Sticky_Content} allowDangerousHtml />
                </Col>
                <Col md={12} lg={4} className="col2">
                    {props.Sticky_CTA_1_Label &&
                        <Link to={`/${url}`} className="btn btn-success">{props.Sticky_CTA_1_Label}</Link>
                    }
                </Col>
              </Row> : 
              <Row className="recommendation center-align"> 
                <Col md={12} lg={2} className="col1">
                </Col>
                <Col md={12} lg={8} className="col1">
                    <ReactMarkdown source={props.Sticky_Content} allowDangerousHtml />
                    <div className="cta">
                    {props.Sticky_CTA_1_Label &&
                        <Link to={`/${url}`} className="btn">{props.Sticky_CTA_1_Label}</Link>
                    }
                    {props.Sticky_CTA_2_Label &&
                        <Link to={`/${url_1}`} className="btn cta-two">{props.Sticky_CTA_2_Label}</Link>
                    }
                    </div>
                </Col>
                <Col md={12} lg={2} className="col1">
                </Col>
              </Row>
                }
            </Container>
          </motion.section>
        )}
      </InView>
    )
  }
export default RecommendationCanHelp
