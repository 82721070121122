import React from "react";
import {Col, Row, Container } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";
import ReactMarkdown from "react-markdown"
import MarkerSlide from "../MarkerSlide/MarkerSlideHistory";

import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const contentItemLeft = {
    hidden: { opacity: 0, x: -50 },
    visible: {
		opacity: 1,
		x: 0,
		transition: {
			ease: [0.33, 1, 0.68, 1],
			duration: 0.6
		}
	}
}
const contentItemRight = {
    hidden: { opacity: 0, x: 50 },
    visible: {
		opacity: 1,
		x: 0,
		transition: {
			ease: [0.33, 1, 0.68, 1],
			duration: 0.6
		}
	}
}

const History = () => {


  const data = useStaticQuery(graphql`
    query{
  	  allStrapiHistories(sort: {fields: Title, order: ASC}) {
	    edges {
	      node {
	        Title
	        Content
	        Image {
	          publicURL
	        }
	      }
	    }
	  }
    }
  `);

	return (
		<div className="review-list history-page-list">
			<Container>
				<Row>
					<Col xl={12}>
						<div className="history-head-slider">
							<MarkerSlide />
						</div>
				
						<ul>
							{data.allStrapiHistories.edges.map(({node}, i) => {
								return (
									<InView {...inViewOptions}>
										{({ ref, inView }) => (
											<motion.li
												key={i}
												className={`d-md-flex ${i % 2 === 0 ? 'justify-content-start' : 'justify-content-end'}`}
												id={`${node.Title.slice(0,3)}0`}
												ref={ref}
												initial="hidden"
												animate={inView ? "visible" : "hidden"}
												variants={i % 2 === 0 ? contentItemLeft : contentItemRight}
											>
												<div className="list-item d-flex">
												{node.Image ? <div className="placeholder"><img loading="lazy" src={node.Image.publicURL} alt={`${node.Title} - Martyn Gerrard`} /></div> : '' }
												<div className="info">
													<h5 className="list-title text-uppercase">{node.Title}</h5>
													<ReactMarkdown source={node.Content} allowDangerousHtml  />
												</div>
												</div>
											</motion.li>
										)}
									</InView>
								)
							})}
						</ul>
					</Col>
				</Row>
			</Container>
		</div>
	)
}


export default History