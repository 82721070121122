import React, { useEffect, useState } from "react";
import { Row, Col, Container, Form } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";

import NewsBreadcrumbs from "../NewsBreadcrumbs/NewsBreadcrumbs"
import BlockHeading from "../BlockHeading/BlockHeading"
import OfficeCard from "../OfficeCard/OfficeCard"
import OfficeCardFilter from "../OfficeCard/OfficeCardFilter"
import OfficeMap from "../office-landing-map"
import OfficeMapFilter from "../office-landing-map-filter"
import "./OfficePage.scss";
import Breadcrumbs from "../../MgServices/Breadcrumb/Breadcrumb";
import Search from "../../News/NewsHead/search-form-office"
import $ from "jquery";
import _ from "lodash"

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 40 
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const OfficePage = (props) => {
  let [addClass, setAddClass] = useState(false);
  let [ftr, setFooter] = useState(false)

  const [ search_text, setSearchText ] = useState('');

  const handlechange = (event) => {
    // remove initial empty spaces
    var search_val = event.target.value.trimStart();
    setSearchText(search_val);
  }
  const [scroll, setScroll] = useState(false);


  /* let lastScrollY = 0
  useEffect(() => {
      window.addEventListener("scroll", () => {
        lastScrollY = window.scrollY
        setScroll(window.scrollY > 40)
        if (lastScrollY > 100) {
          document.querySelector('#location-map').classList.add('map-to-top')
        }
        else {
          document.querySelector('#location-map').classList.remove('map-to-top')          
        }
      })
  }, []) */

  const data = useStaticQuery(graphql`
    query{
      allStrapiOffices(filter: {Publish: {eq: true}}) {
        edges {
          node {
            id
            Name
            strapiId
            Tile_Image {
              internal {
                description
              }
            }
            Address
            Phone
            URL
            Latitude
            Longtitude
            imagetransforms {
              Tile_Image_Transforms
            }
            Areas
            Postcode
            Sort
          }
        }
      }
    }
  `);


  const[addSearch,setAddSearch] = useState(false);

  const[alloffice, setMGOffice] = useState([])

    const addingSearch=()=>{
      setAddSearch(!addSearch)
  }

  const all_office = data.allStrapiOffices.edges

  useEffect(()=>{
	var num_start = 500
  
  let offices = _.each(all_office, item => {
		if(_.toNumber(item.node.Sort) == 0) {
		  item.Sort = num_start = num_start + 1
		}
		else {
			item.Sort = _.toNumber(item.node.Sort)
		}
	  });

    setMGOffice(_.sortBy(offices, [function(o) { return o.Sort; }]))
  },[])


  return (
    <InView threshold={0}>
      {({ ref, inView }) => {
        return(
        <motion.div
          className="office_section"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <Container>
            <Row className="align-items-center">
              <Col lg={12}>
                <motion.div variants={titleVariants}>
                  <Breadcrumbs id={props.id} tag="contact-landing" />
                  {props.Modules.map((Modules, i) => {
                    return (
                      <React.Fragment key={i}>
                        {Modules.Header_Title !== null &&
                          <BlockHeading content={Modules.Header_Title} />
                        }
                      </React.Fragment>
                    )
                  })}
                  <div className="news-head office-listing-search">
                    <div className="navbar-nav">

                    <div className="office-head-search">
                    <Form onSubmit={e => { e.preventDefault(); }}>
                      <Form.Group>
                        <Form.Control type="text" id="search" value={search_text} placeholder="Search by Office name, Area or Postcode" onChange={handlechange}/>
                      </Form.Group>
                    </Form>
                    </div>

                    </div>
                  </div>
                </motion.div>
                {search_text != '' ? 
                <OfficeCardFilter text={search_text} offices={data.allStrapiOffices}/> :
                <OfficeCard text={search_text} offices={alloffice}/> }

              </Col>

            </Row>
          </Container>
          <Col lg={5} className={`office_map office-main-landing-map  ${addClass && ftr === false ? 'office_map-fixed' : ""}  pl-0 pr-0 content`}>
            {search_text != '' ? 
              <OfficeMapFilter text={search_text} offices={data.allStrapiOffices}/> :
              <OfficeMap text={search_text} offices={data.allStrapiOffices}/> }
          </Col>
        </motion.div>
      )}}
    </InView>
  )
}

export default OfficePage;

