import { Link } from "@StarberryUtils";
import React from "react"
import ModalTiny from "react-bootstrap/Modal"
import "./PersonInfo.scss"
// import listImg from "../../../images/list.svg"
// import markerImg from "../../../images/marker.svg"
import ReactMarkdown from "react-markdown"
import { openPopupWidget } from 'react-calendly';
import Form from "../../forms/reach-us-form"

const PersonInfo = (props, { prefill, pageSettings, utm }) => {
  let url = props.url
  const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });

    const [modalAskformOpen,setAskformOpen] = React.useState(false);
    const openAskformModal = () => {
      setAskformOpen(true);
    }
    const closeAskformModal = () => {
      setAskformOpen(false);
    }
  return (
    <div className="person-info-block">
          <div className="person-name-blcok">
             <h1>{props.name} {props.membership && <span className="membership">{props.membership}</span> }</h1>
             <span>{props.Designation}</span>
          </div>
          <div className="d-none d-md-block btn-group-block">
          <Link className="btn" to="#" onClick={openAskformModal}>contact {props.name.split(' ')[0]}</Link>
          {props.url ? <span className="btn ml-2"  onClick={onClick}>Book an appointment</span> : '' }
          </div>
           <div className="person-address-info">
             {props.Phone ? <span>
              <a href={`tel:+${props.Phone}`}>
                 {props.Phone}
              </a>
             </span> : '' }
             <span>
                {props.Address}
            </span>
             </div>            
           <div className="person-info-detail">
              {props.About ? <ReactMarkdown source={props.About} allowDangerousHtml /> : '' }
           </div>

        <section className="fixed-btns d-flex d-md-none">
            <Link to="#" className={`${props.url ? '' : 'full-width-cta-person'} btn book-btn`} onClick={openAskformModal}>contact {props.name.split(' ')[0]}</Link>
            {props.url ? <Link to="#" className="btn ask-btn btn-success" onClick={onClick}>Book an appointment</Link> : '' }
          
        </section>
        
      <ModalTiny backdrop="static" show={modalAskformOpen} onHide={closeAskformModal} className="book-a-viewing-modal people-details-form-modal-main">
        <ModalTiny.Header closeButton className="contact-close-btn">
          <ModalTiny.Title className="w-100"><h2 className="text-center mb-0 text-uppercase">Contact {props.name}</h2></ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body className="event-section people-details-form-modal"><Form to_email_id={props.Email} source="person" personname={props.name}/></ModalTiny.Body>
      </ModalTiny>
           </div>
           
  
  )
}

export default PersonInfo
