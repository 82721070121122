import { Link } from "@StarberryUtils";
import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {Row, Col, Container,Breadcrumb } from 'react-bootstrap';
import  "./Breadcrumb.scss";
//import "animate.css/animate.min.css";

import { GetURL } from "../../common/site/functions";

const Breadcrumbs =(props) => {

  const data = useStaticQuery(graphql`
    query {
      allStrapiAllMenus {
        edges {
          node {
            URL
            Alias
            Label
            Sub_Parent {
              Label
              Alias
              URL
              id
              Article
            }
            Main_Parent {
              Label
              Alias
              URL
              id
              Article
            }
            Link_Type
            id
            strapiId
          }
        }
      }
    }
  `);

    let PageURL = data.allStrapiAllMenus.edges.filter(item => item.node.strapiId === props.id).pop().node;

    let main_parent_url = ''
    if(PageURL.Main_Parent !== null)
    {
      main_parent_url = GetURL(PageURL.Main_Parent.id)
    }

    let sub_parent_url = ''
    if(PageURL.Sub_Parent !== null)
    {
      sub_parent_url = GetURL(PageURL.Sub_Parent.id)
      if(sub_parent_url == "property-services/landlord") {
        sub_parent_url = "property-services/landlord/letting-a-home"
      }
      else if(sub_parent_url == "property-services/buyer") {
        sub_parent_url = "property-services/buyer/buying-home"
      }
      else if(sub_parent_url == "property-services/seller") {
        sub_parent_url = "property-services/seller/selling-your-home"
      }
      else if(sub_parent_url == "property-services/tenant") {
        sub_parent_url = "property-services/tenant/renting-a-home"
      }
      else if(sub_parent_url == "property-services/new-homes") {
        sub_parent_url = "property-services/new-homes/buying-new-homes"
      }
    }

    return(
  <div className={`breadcrumb-wrp ${props.tag !== 'service-landing-banner' ? 'text-center' : ''} ${props.tag === 'market-property' ? 'market-property-breadcrumb' : ''} ${props.tag === 'contact-landing' ? 'contact-landing-breadcrumb' : ''} components--MgServices--Breadcrumb--Breadcrumb`} >
  {props.tag !== 'service-landing-banner' ?
    <Container className="bc">
        <Row className={`${props.tag === 'contact-landing' ? '' : 'align-items-center'}`}> 
        <Col lg={12}>
            <Breadcrumb>
                <Breadcrumb.Item className="text-uppercase"><Link to="/">home</Link></Breadcrumb.Item>
                
                {PageURL.Main_Parent !== null &&
                <Breadcrumb.Item className="text-uppercase"><Link to={`/${main_parent_url}`}>{PageURL.Main_Parent.Label}</Link></Breadcrumb.Item>
                }

                {PageURL.Sub_Parent !== null &&
                <Breadcrumb.Item className="text-uppercase"><Link to={`/${sub_parent_url}`}>{PageURL.Sub_Parent.Label}</Link></Breadcrumb.Item>
                }

                {props.page ? <Breadcrumb.Item className="text-uppercase" active><Link to="../">{PageURL.Label}</Link></Breadcrumb.Item> : <Breadcrumb.Item className="text-uppercase" active>{PageURL.Label}</Breadcrumb.Item> }

                {props.page ? <Breadcrumb.Item className="text-uppercase" active>{props.page}</Breadcrumb.Item> : ''}
            </Breadcrumb>
        </Col>
        </Row>
    </Container>
    :
    <Breadcrumb>
    <Breadcrumb.Item className="text-uppercase"><Link to="/">home</Link></Breadcrumb.Item>

    {PageURL.Main_Parent !== null &&
    <Breadcrumb.Item className="text-uppercase"><Link to={`/${main_parent_url}`}>{PageURL.Main_Parent.Label}</Link></Breadcrumb.Item>
    }

    {PageURL.Sub_Parent !== null &&
    <Breadcrumb.Item className="text-uppercase">
      {PageURL.Sub_Parent.Article === null ? <span className="no-link">{PageURL.Sub_Parent.Label}</span> : <Link to={`/${sub_parent_url}`}>{PageURL.Sub_Parent.Label}</Link> }</Breadcrumb.Item>
    }

    {props.page ? <Breadcrumb.Item className="text-uppercase" active><Link to="../">{PageURL.Label}</Link></Breadcrumb.Item> : <Breadcrumb.Item className="text-uppercase" active>{PageURL.Label}</Breadcrumb.Item> }


    {props.page ? <Breadcrumb.Item className="text-uppercase" active>{props.page}</Breadcrumb.Item> : ''}
    </Breadcrumb>
    }
  </div>
)
    }

export default Breadcrumbs;

