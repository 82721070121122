import { Link } from "@StarberryUtils";
import React from "react";
import {Col, Row, Container } from 'react-bootstrap';
import ReactMarkdown from "react-markdown"
// import HelpCard from "./HelpCard/HelpCard"
// import BgShadowWhite from "../../../images/bg-shadow-white2.svg";
import  "../CanHelp/CanHelp.scss";
import './HelpCard/HelpCard.scss';
// import GenerateLink from "../../common/site/generate-link";
import { GetURL } from "../../common/site/functions";
import { GetMenuTileImageURL } from "../../common/site/menu-tile-image-path";

import { inViewOptions } from '../../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.3
    }
  }
}
const titleVariants = {
  hidden: {
    opacity: 0,
    y: 40 
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}
const contentItem = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0 }
}

const HelpCard = (props) => {
  return (
    <>
      {props.Links.map((link, index) => {
          let url = GetURL(link.URL.id,props.menus)
          let tile_image_url = GetMenuTileImageURL(link.URL.id,props.menus)
          return (
            <Col md={6} lg={4} className="list-wrap" key={index}>
              <motion.div
                variants={contentItem}
              >
                <Link to={`/${url}`} className="list-item d-flex align-items-center">
                  <figure><img loading="lazy" src={tile_image_url} alt={link.Label} title="" /></figure>
                  <div className="d-flex justify-content-between align-items-center w-100"><strong>{link.Label}</strong> <span><i className="icon-longarrow-rgt"></i></span></div>
                </Link>
              </motion.div>
            </Col>
          )
      })}
    </>
  )
}

const CanHelpStyleTwo=(props)=> {
  return(
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className="help-sec components--Home--CanHelp--CanHelp"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <Container>
            <Row>
              <Col md={12}>
                <motion.div variants={titleVariants}>
                  {props.tag != 'markdown' ?
                  <h2>{props.title ? props.title : ''}</h2> :
                  <ReactMarkdown source={props.title ? props.title : ''} allowDangerousHtml />
                  }
                </motion.div>
              </Col>
            </Row>
            <Row className="help-list components--Home--CanHelp--HelpCard--HelpCard">
              <HelpCard Links={props.links} />
            </Row>

          </Container>
        </motion.section>
      )}
    </InView>
  )
}

export default CanHelpStyleTwo