import React,{useState, useEffect} from "react";
import { Link } from "@StarberryUtils";
import {Col, Row, Container } from 'react-bootstrap';
import  "../Static/Intro/Intro.scss";
import { useStaticQuery, graphql } from "gatsby";
import VideoPlay from "../Play/custom-video";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";

import { inViewOptions } from '../utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const containerItem = {
    hidden: { opacity: 0, y: 50, scale: 0.8 },
    visible: {
      opacity: 1,
	  y: 0,
	  scale: 1,
      transition: {
        ease: [0.33, 1, 0.68, 1],
        duration: 0.8
      }
    }
}
const MotionCol = motion(Col)

const postsPerPage = 20;
let arrayForHoldingPosts = [];

  
const AreaGuideLanding = () =>{


  const [postsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(20);



  const data = useStaticQuery(graphql`
    query{
      allStrapiAreaGuides(
        filter: { Publish: { eq: true } }
        sort: { fields: Title, order: ASC }
      ) {
        edges {
          node {
            Title
            URL
            Embed_Video_URL
            Tile_Image {
              internal {
                description
              }
            }
            strapiId
            imagetransforms {
              Tile_Image_Transforms
            }
          }
        }
      }
    }
  `);


   const loopWithSlice = (start, end) => {
    const slicedPosts = data.allStrapiAreaGuides.edges.slice(start, end);
    arrayForHoldingPosts = [...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  const loopWithSliceMore = (start, end) => {
    const slicedPosts = data.allStrapiAreaGuides.edges.slice(start, end);
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  const handleShowMorePosts = () => {
    loopWithSliceMore(next, next + postsPerPage);
    setNext(next + postsPerPage);
  };


  useEffect(() => {
    loopWithSlice(0, postsPerPage);
  }, [data.allStrapiAreaGuides.edges]);



  const all_post_length = data.allStrapiAreaGuides.edges.length



  return(
    <div className="review-resort area-guide-landing-list components-area-guide-area-guide-landing">
      <Container>
            <Row>
              {postsToShow.map(({node}, i) => {

              const image_url = node.Tile_Image.internal.description ? node.Tile_Image.internal.description.replace("File ","").replace('"','').replace('"','') : ''; 

              let processedImages = JSON.stringify({});
              if (node?.imagetransforms?.Tile_Image_Transforms) {
                  processedImages = node.imagetransforms.Tile_Image_Transforms;
              }

                return (
                  <InView {...inViewOptions}>
							      {({ ref, inView }) => (
                      <MotionCol
                        md="6"
                        xl={3}
                        key={i}
                        ref={ref}
                        initial="hidden"
                        animate={inView ? "visible" : "hidden"}
                        variants={containerItem}
                      >
                        <div className="video-card ">
                        <div className="area-tile-image-link">
                          <Link to={node.URL}>
                            <figure className="video-img">
                              <ImageTransform imagesources={image_url} renderer="srcSet" imagename="area-guides.Tile_Image.tile" attr={{ alt: node.Title+ ' - Martyn Gerrard', class:'' }} imagetransformresult={processedImages} id={node.strapiId}/>
                            </figure>
                          </Link>
                          {node.Embed_Video_URL ? <VideoPlay title={node.Title} url={node.Embed_Video_URL}/> : '' }
                          </div>
                          <Link to={node.URL}>  
                            <strong className="video-title d-block">{node.Title}</strong>
                          </Link>
                        </div>
                      </MotionCol>
                    )}
                  </InView>
                )
              })}
            </Row>
      </Container>
        {arrayForHoldingPosts.length !== all_post_length ?
          <div className="area-guide-load-more"><button className="btn btn-pagination" onClick={handleShowMorePosts}>Load more</button></div>
          : ''
        }


    </div>
  )
}

export default AreaGuideLanding